import { IEmail } from 'client/email/types';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import EmailRenderModal from 'client/email/EmailRenderModal';
import ObjectId from 'client/components/ObjectId';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import * as CustomerSurveyFormatters from 'client/customerSurvey/CustomerSurveyFormatters';
import * as UserFormatters from 'client/user/UserFormatters';
import * as CompanyEventBatchFormatters from 'client/companyEventBatch/CompanyEventBatchFormatters';

interface EmailTemplateProps {
  value?: IEmail['view'];
}

export function EmailTemplate (props: EmailTemplateProps) {
  const { value } = props;
  if (!value) return null;
  switch (value) {
    default: return <>{value}</>;
    case 'email.common': return <>Vanlig</>;
    case 'email.customerSurvey': return <>Kundundersökning</>;
    case 'email.notifyNewReport': return <>Ny Rapport</>;
    case 'email.resetPassword': return <>Återställ Lösenord</>;
    case 'email.companyEventCustomerBatch': return <>Kundbatch</>;
    case 'email.companyEventUserBatch': return <>Användarbatch</>;
  }
}

interface EmailModalButtonProps {
  value: IEmail;
}

export function EmailModalButton (props: EmailModalButtonProps) {
  const { value: email } = props;
  if (!email.html) return <span>{email.id}</span>;

  return (
    <ModalOpeningButton
      Modal={EmailRenderModal as React.FC}
      modalProps={{ emailId: email.id }}
      variant="outline-primary"
      size="sm"
      className="py-0"
      buttonAs={ObjectId}
      as="a"
      href="#"
    >
      {email.id}
    </ModalOpeningButton>
  );
}

interface EmailObjectProps {
  value: IEmail;
}

export function EmailObject (props: EmailObjectProps) {
  const { value: email } = props;
  if (email.customer_survey_id) return <CustomerSurveyFormatters.CustomerSurveyAnchor value={email.customer_survey_id} />;
  if (email.company_event_customer_batch_id) return <CompanyEventBatchFormatters.CompanyEventCustomerBatchAnchor value={email.company_event_customer_batch_id} />;
  if (email.company_event_user_batch_id) return <CompanyEventBatchFormatters.CompanyEventUserBatchAnchor value={email.company_event_user_batch_id} />;
  if (email.user_id) return <UserFormatters.UserAnchor value={email.user_id} />;
  if (email.customer_id) return <CustomerFormatters.CustomerAnchor value={email.customer_id} />;
  return null;
}

interface EmailRecipientProps {
  value: IEmail;
}

export function EmailRecipient (props: EmailRecipientProps) {
  const { value:email } = props;
  if (!email.to_address) return <>-</>;
  return (
    <span>
      {email.to_name ?? ''} - <a href={`mailto:${email.to_address}`}>{email.to_address}</a>
    </span>
  );
}
