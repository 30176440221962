import React, { useCallback, useMemo } from 'react';
import { preventDefaultSubmit } from 'client/utils/form';
import { TableFilterRenderDefinition } from 'client/table/TableUtils';
import { Button, Form } from 'react-bootstrap';
import TableSettingsButton from 'client/table/TableSettingsButton';
import {ColumnDefinition, FilterDefinition} from 'client/table/types';
import RefreshButton from 'client/buttons/RefreshButton';
import { isEmpty, pickBy, isNull, keyBy } from 'lodash';

// FIXME better type
type FilterParams = Record<string, any>;

interface TableControlPanelProps {
  filterDefinitions?: FilterDefinition<any>[];
  columnDefinitions: ColumnDefinition<any>[];

  filterParams: FilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;

  filtersVisible?: string[];
  setFiltersVisible?: React.Dispatch<React.SetStateAction<string[]>>;

  columnsVisible?: string[];
  setColumnsVisible?: React.Dispatch<React.SetStateAction<string[]>>;

  defaultColumnsVisible?: string[];
  defaultFiltersVisible?: string[];

  onReload?: () => void;
  onReset?: () => void;
  isLoading: boolean;
  size?: 'sm';
}

export default function TableControlPanel (props: TableControlPanelProps) {
  const {
    filterParams,
    setFilterParams,
    filterDefinitions,
    isLoading,
    onReload,
    onReset,
    columnDefinitions,
    columnsVisible,
    setColumnsVisible,
    size,
    filtersVisible:outerFiltersVisible,
    setFiltersVisible,
    defaultColumnsVisible,
    defaultFiltersVisible,
  } = props;

  const filtersVisible = outerFiltersVisible ?? (filterDefinitions ? filterDefinitions.map(def => def.id) : []);

  const noFilters = isEmpty(pickBy(filterParams, v => v !== '' && !isNull(v)));

  const filterDefinitionsById = useMemo(() => keyBy(filterDefinitions, def => def.id), [filterDefinitions]);

  const onChange = useCallback((id: string, value: any) => {
    setFilterParams(prevValue => ({...prevValue, [id]: value}));
  }, [setFilterParams]);

  const hasSettingsButton = Boolean(setColumnsVisible || setFiltersVisible);

  return (
    <Form className="d-flex justify-content-between flex-wrap gap-3" onSubmit={preventDefaultSubmit}>
      {filtersVisible.map(id => (
        <TableFilterRenderDefinition
          value={filterParams[id]}
          key={id}
          filterDefinition={filterDefinitionsById[id]}
          onChange={onChange}
          size={size}
        />
      ))}
      <div className="d-flex flex-wrap gap-3">
        {hasSettingsButton && (
          <TableSettingsButton
            columnDefinitions={columnDefinitions}
            columnsVisible={columnsVisible}
            setColumnsVisible={setColumnsVisible}
            filtersVisible={filtersVisible}
            setFiltersVisible={setFiltersVisible}
            filterDefinitions={filterDefinitions}
            size={size}
            defaultColumnsVisible={defaultColumnsVisible}
            defaultFiltersVisible={defaultFiltersVisible}
          />
        )}
        {onReset && (
          <Button
            title="Återställ tabellen"
            onClick={onReset}
            variant="outline-primary"
            className="d-flex align-items-center justify-content-center"
            size={size}
            disabled={noFilters}
          >
            Återställ
          </Button>
        )}
        {onReload && (
          <RefreshButton size={size} onClick={onReload} disabled={isLoading} />
        )}
      </div>
    </Form>
  );
}
