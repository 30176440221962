import React from 'react';
import { Alert } from 'react-bootstrap';
import DateFormat from 'client/components/DateFormat';
import moment from 'moment';
import { AlertCircle, ThumbsUp } from 'react-feather';

interface AgeWarningAlertProps {
  date?: null | string | Date;
  warningAge: number;
  dangerAge: number;
}

export default function AgeWarningAlert (props: AgeWarningAlertProps) {
  const { date, warningAge, dangerAge } = props;
  if (typeof date === 'undefined') return null;

  if (date === null) {
    return (
      <Alert variant="danger" className="px-1 py-0 d-inline-flex align-items-center gap-1 mb-0">
        <AlertCircle size={14} />
        {' '}Aldrig
      </Alert>
    );
  }

  const daysAgo = moment().diff(date, 'days');
  const isWarning = moment(date).isBefore(moment().subtract(warningAge, 'days'));
  const isDanger = moment(date).isBefore(moment().subtract(dangerAge, 'days'));

  let variant = 'success';
  if (isDanger) variant = 'danger';
  else if (isWarning) variant = 'warning';

  return (
    <Alert variant={variant} className="px-1 py-0 d-inline-flex align-items-center gap-1 mb-0">
      {variant === 'success' ? (
        <ThumbsUp size={14} />
      ) : (
        <AlertCircle size={14} />
      )}
      <DateFormat value={date} format="YYYY-MM-DD" />
      <small>({daysAgo} dagar sedan)</small>
    </Alert>
  );
}
