import React, { useMemo, useState, useId, useEffect, useRef } from 'react';
import HelperTooltip from 'client/components/HelperTooltip';
import { BranchRow } from 'client/branches/types';
import TagForm from 'client/components/TagFormTools';
import { TChangeValue } from 'client/utils/form';
import LoadingButton from 'client/buttons/LoadingButton';
import axios from 'client/axios';
import { useMutation } from '@tanstack/react-query';
import {
  Button as BsButton,
  Form,
} from 'react-bootstrap';
import ErrorAlert from 'client/components/ErrorAlert';
import {isEqual} from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import type TypeaheadClass from 'react-bootstrap-typeahead/types/core/Typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

interface IIndustryReportBranchAndSNICodeSelector {
  branches: BranchRow[];
  sniCodesValue: string[];
  branchIdValue: string | null;
  defaultBranchIdValue: string | null;
  onChangeValue: TChangeValue;
}
const IndustryReportBranchAndSNICodeSelector: React.FC<IIndustryReportBranchAndSNICodeSelector> = React.memo(function IndustryReportBranchAndSNICodeSelector (props: IIndustryReportBranchAndSNICodeSelector) {
  const {
    branches,
    sniCodesValue,
    branchIdValue,
    defaultBranchIdValue,
    onChangeValue,
  } = props;

  const ref = useRef<TypeaheadClass>();
  const id = useId();

  const getAllBranchesMutation = useMutation<BranchRow[], Error>({
    mutationKey: ['IndustryReportBranchAndSNICodeSelector'],
    mutationFn: () => axios.get('/api/branch').then(r => r.data),
    onSuccess: branches => {
      setBranchesList(branches.map((branch: BranchRow) => ({
        ...branch,
        label: `${branch.id} - ${branch.branch_name}`,
      })));
    },
  });

  const [branchesList, setBranchesList] = useState<BranchRow[]>(branches);

  const onChangeBranchId = (value?: string) => {
    onChangeValue('report_settings.branch_id', value);
    const branch = branchesList.find(b => b.id === value);
    if (branch) {
      onChangeValue('report_settings.sni_codes', branch.sni_codes);
    }
  };

  const onClickResetBranchId = () => {
    onChangeValue('report_settings.branch_id', defaultBranchIdValue);
    const branch = branchesList.find(b => b.id === defaultBranchIdValue);
    if (branch) {
      onChangeValue('report_settings.sni_codes', branch.sni_codes);
      ref.current?.setState({ selected: [branch] });
    }
  };

  const onClickResetSniCodes = () => {
    const branch = branchesList.find(b => b.id === branchIdValue);
    if (!branch) return;
    onChangeValue('report_settings.sni_codes', branch.sni_codes);
  };

  const { hasModifiedSniCodes, defaultSelected } = useMemo(() => {
    const branch = branchesList.find(b => b.id === branchIdValue);
    return {
      hasModifiedSniCodes: branch ? !isEqual(branch.sni_codes, sniCodesValue) : false,
      defaultSelected: branch,
    };
  }, [branchesList, branchIdValue, sniCodesValue]);

  const getLabelKey = (option: BranchRow | any) => option ? `${option.id} - ${option.branch_name}` : '(Inget värde)';

  const emulateTypeaheadInputChange = (value: string) => {
    if (!ref.current) return;
    ref.current._handleInputChange({
      currentTarget: { value },
      persist: () => null } as any,
    );
  };

  // if the selected branch can't be found in the branch list load in all branches and
  // try to find it there instead
  useEffect(() => {
    const branchIdInDefaultList = branchesList.some(branch => branch.id === branchIdValue);
    if (branchIdValue && !branchIdInDefaultList) {
      getAllBranchesMutation.mutateAsync().then(branchesList => {
        const branch = branchesList.find(branch => branch.id === branchIdValue);
        if (branch) {
          emulateTypeaheadInputChange(getLabelKey(branch));
          if (ref.current) {
            ref.current.blur();
          }
        }
      });
    }
  }, [branches, branchIdValue, ref.current]);

  return (
    <div>
      <Form.Group className="mb-3">
        <ErrorAlert error={getAllBranchesMutation.error} />
        <div className="d-flex align-items-baseline justify-content-between mb-2 flex-wrap">
          <Form.Label htmlFor={id}>
            Huvudbransch{' '}
            <HelperTooltip>
              Branschrapportens huvudbransch. Kommer att synas på branschrapportens framsida.
            </HelperTooltip>
          </Form.Label>
          <div className="d-flex gap-2 flex-wrap">
            <LoadingButton
              size="sm"
              variant="outline-primary"
              onClick={() => getAllBranchesMutation.mutate()}
              isLoading={getAllBranchesMutation.isPending}
            >
              Hämta alla valbara branscher
            </LoadingButton>
            <BsButton
              size="sm"
              variant="outline-primary"
              onClick={onClickResetSniCodes}
              disabled={!hasModifiedSniCodes}
            >
              Återställ branschens SNI-koder
            </BsButton>
            <BsButton
              size="sm"
              variant="outline-primary"
              onClick={onClickResetBranchId}
              disabled={defaultBranchIdValue === branchIdValue}
            >
              Återställ ursprunglig bransch
            </BsButton>
          </div>
        </div>

        {/* It works in uncontolled mode, so we need to update it's state manually */}
        <Typeahead
          id={id}
          className="branch-sni-selector-typeahead"
          ref={ref as any}
          labelKey={getLabelKey}
          options={branchesList || []}
          placeholder="Sök på huvudbransch"
          paginationText="Visa fler resultat"
          paginate={false}
          onFocus={() => {
            emulateTypeaheadInputChange('');
          }}
          onBlur={() => {
            if (!ref.current) return;

            const isDirty = ref.current.state.text !== getLabelKey(defaultSelected);

            if (ref.current.state.activeIndex === -1 && isDirty) {
              emulateTypeaheadInputChange(getLabelKey(defaultSelected));
            }
          }}
          defaultSelected={defaultSelected ? [defaultSelected] : undefined}
          renderMenuItemChildren={(option) => <>{getLabelKey(option)}</>}
          onChange={(selectedItems) => {
            const item = selectedItems[0] as BranchRow;
            if (!item) return;
            onChangeBranchId(item.id);
            ref.current?.blur();
          }}
        />
      </Form.Group>
      <TagForm
        name="report_settings.sni_codes"
        values={sniCodesValue}
        onChangeValues={onChangeValue}
        inputProps={{htmlSize: 5, minLength: 5, maxLength: 5, pattern: '^\\d{5}$'}}
      />
    </div>
  );
});
export default IndustryReportBranchAndSNICodeSelector;
