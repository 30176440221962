import {ColumnDefinition} from 'client/table/types';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import * as CsFormatters from 'client/cs/CsFormatters';
import * as csStrings from 'client/cs/strings';

// returns columns returned by CompanyController
export const companyControllerList = (): ColumnDefinition<any>[] => [
  columnDefs.cell(csStrings.csBasic.label('org_number'), props => (
    <CompanyFormatters.CompanyAnchor value={props.row.org_number} />
  )),
  columnDefs.simple(csStrings.csBasic.label('company_name')),
  columnDefs.simple(csStrings.csBasic.label('registered_town')),
  columnDefs.simple(csStrings.csBasic.label('registered_co_street')),
  columnDefs.simple(csStrings.csBasic.label('registered_street')),
  columnDefs.simple(csStrings.csBasic.label('registered_zipcode')),
  columnDefs.simple(csStrings.csBasic.label('registered_community_code')),
  columnDefs.simple(csStrings.csBasic.label('registered_community_name')),
  columnDefs.simple(csStrings.csBasic.label('registered_region_code')),
  columnDefs.simple(csStrings.csBasic.label('registered_region_name')),
  columnDefs.simple(csStrings.csBasic.label('post_co_street')),
  columnDefs.simple(csStrings.csBasic.label('post_street')),
  columnDefs.simple(csStrings.csBasic.label('post_zipcode')),
  columnDefs.simple(csStrings.csBasic.label('post_town')),
  columnDefs.simple(csStrings.csBasic.label('post_community_code')),
  columnDefs.simple(csStrings.csBasic.label('post_community_name')),
  columnDefs.simple(csStrings.csBasic.label('post_region_code')),
  columnDefs.simple(csStrings.csBasic.label('post_region_name')),
  columnDefs.simple(csStrings.csBasic.label('visiting_street')),
  columnDefs.simple(csStrings.csBasic.label('visiting_zipcode')),
  columnDefs.simple(csStrings.csBasic.label('visiting_town')),
  columnDefs.simple(csStrings.csBasic.label('visiting_community_code')),
  columnDefs.simple(csStrings.csBasic.label('visiting_community_name')),
  columnDefs.simple(csStrings.csBasic.label('visiting_region_code')),
  columnDefs.simple(csStrings.csBasic.label('visiting_region_name')),
  columnDefs.date(csStrings.csBasic.label('status_date')),
  columnDefs.simple(csStrings.csBasic.label('status_code')),
  columnDefs.cell(csStrings.csBasic.label('status_text_high'), props => (
    <CompanyFormatters.StatusLabel value={props.row.status_text_high} />
  )),
  columnDefs.simple(csStrings.csBasic.label('status_text_detailed')),

  columnDefs.simple(csStrings.csBasic.label('complete_ef_pnr')),

  columnDefs.simple(csStrings.csBasic.label('phone_number')),
  columnDefs.simple(csStrings.csBasic.label('fax_number')),
  columnDefs.simple(csStrings.csBasic.label('orgnr_status_affected')),
  columnDefs.date(csStrings.csBasic.label('company_formed_date')),
  columnDefs.date(csStrings.csBasic.label('registration_date')),
  columnDefs.date(csStrings.csBasic.label('deregistration_date')),
  columnDefs.simple(csStrings.csBasic.label('legalgroup_code')),
  columnDefs.simple(csStrings.csBasic.label('legalgroup_text')),
  columnDefs.cell(csStrings.csBasic.label('f_tax_reg'), props => (
    <CsFormatters.CsActive value={props.row.f_tax_reg} />
  )),
  columnDefs.cell(csStrings.csBasic.label('f_tax_yes_no'), props => (
    <CsFormatters.CsYesNo value={props.row.f_tax_yes_no} />
  )),
  columnDefs.cell(csStrings.csBasic.label('f_tax_reg_spec'), props => (
    <CompanyFormatters.CompanyFTaxRegSpec value={props.row.f_tax_dereg_reason_code} />
  )),
  columnDefs.date(csStrings.csBasic.label('f_tax_start_date')),
  columnDefs.date(csStrings.csBasic.label('f_tax_end_date')),
  columnDefs.simple(csStrings.csBasic.label('f_tax_dereg_reason_code')),
  columnDefs.cell(csStrings.csBasic.label('moms_reg'), props => (
    <CsFormatters.CsActive value={props.row.moms_reg} />
  )),
  columnDefs.cell(csStrings.csBasic.label('moms_yes_no'), props => (
    <CsFormatters.CsYesNo value={props.row.moms_yes_no} />
  )),
  columnDefs.date(csStrings.csBasic.label('moms_start_date')),
  columnDefs.date(csStrings.csBasic.label('moms_end_date')),
  columnDefs.cell(csStrings.csBasic.label('sni_code'), props => (
    <CompanyFormatters.CompanySniCode value={props.row} />
  )),
  columnDefs.simple(csStrings.csBasic.label('sni_text')),
  columnDefs.cell(csStrings.csBasic.label('accountant_reservation'), props => (
    <CsFormatters.CsYesNo value={props.row.accountant_reservation} />
  )),
  columnDefs.date(csStrings.csBasic.label('date_accountant_reservation')),
  columnDefs.simple(csStrings.csBasic.label('number_of_units')),
  columnDefs.simple(csStrings.csBasic.label('top_director_name')),
  columnDefs.simple(csStrings.csBasic.label('top_director_function')),
  columnDefs.simple(csStrings.csBasic.label('turnover_interval')),
  columnDefs.simple(csStrings.csBasic.label('nbr_employees_interval')),
  columnDefs.date(csStrings.csBasic.label('created_at')),
  columnDefs.date(csStrings.csBasic.label('updated_at')),
];
