import React from 'react';
import { ICsBasicExtendedWithRelations, ICsUnit } from 'client/cs/types';
import { Card, Table } from 'react-bootstrap';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import DateFormat from 'client/components/DateFormat';
import { StatusLabel } from 'client/company/CompanyFormatters';
import styled from 'styled-components';

// TODO this might deserve a special file/component
const SeamlessTableBody = styled.tbody`
  tr:first-child th, tr:first-child td {
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
  }
  tr:last-child th, tr:last-child td {
    border-bottom: none;
  }
`;

interface CompanyInfoCardProps {
  className?: string;
  company: ICsBasicExtendedWithRelations;
}

export const CompanyInfoCardOverview: React.FC<CompanyInfoCardProps> = React.memo(function CompanyInfoCardOverview (props: CompanyInfoCardProps) {
  const { company, className } = props;
  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between align-items-center border-bottom-0 border-bottom-0">
        <Card.Title className="mb-0" as="h6">
          Allmän info
        </Card.Title>
      </Card.Header>
      <div className="table-responsive">
        <Table className="mb-0">
          <SeamlessTableBody>
            {company.top_director_name && (
              <tr>
                <th>{company.top_director_function}</th>
                <td>{company.top_director_name}</td>
              </tr>
            )}
            <TableRow label="Bolagsform" value={company.legalgroup_text} />
            <TableRow label="Status" value={company.status_code}>
              <StatusLabel value={company.status_text_high} />
            </TableRow>
            <TableRow label="Statusdatum" value={company.status_date} />
            <TableRow label="Registreringsdatum" value={company.registration_date}>
              <DateFormat value={company.registration_date} format="YYYY-MM-DD" />
            </TableRow>
            <TableRow label="Bolagsbildning" value={company.registration_date}>
              <DateFormat value={company.company_formed_date} format="YYYY-MM-DD" />
            </TableRow>
            <TableRow label="Avregistrering" value={company.deregistration_date}>
              <DateFormat value={company.deregistration_date} format="YYYY-MM-DD" />
            </TableRow>
            <TableRow label="Omsättning" value={company.turnover_interval} />
            <TableRow label="Anställda" value={company.nbr_employees_interval} />
            <TableRow label="SNI" value={company.sni_code_original}>
              {company.sni_code_original} — {company.sni_text}
            </TableRow>
          </SeamlessTableBody>
        </Table>
      </div>
    </Card>
  );
});

interface CompanyInfoCardContactDetails extends CompanyInfoCardProps {
  showGoogleSearchUrl?: boolean;
}

export const CompanyInfoCardContactDetails: React.FC<CompanyInfoCardContactDetails> = React.memo(function CompanyInfoCardContactDetails (props: CompanyInfoCardContactDetails) {
  const { company, className, showGoogleSearchUrl } = props;
  const { cs_units:units } = company;
  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between align-items-center border-bottom-0">
        <Card.Title className="mb-0" as="h6">
          Kontaktuppgifter
        </Card.Title>
      </Card.Header>
      <div className="table-responsive">
        <Table className="mb-0">
          <SeamlessTableBody>
            <TableRow label="Telefon" value={company.phone_number} />
            <TableRow label="Fax" value={company.fax_number} />
            <TableRow label="Antal driftställen" value={`${units.length} st`} />
          </SeamlessTableBody>
        </Table>
      </div>
      {showGoogleSearchUrl && company.company_name && (
        <Card.Footer className="d-flex flex-wrap gap-1 align-items-center small">
          Sök på företagsnamnet:
          <a href={`https://www.google.com/search?q=${encodeURI(company.company_name)}`} target="_blank" rel="noreferrer">
            Google
          </a>
        </Card.Footer>
      )}
    </Card>
  );
});

export const CompanyInfoPostAddressCard: React.FC<CompanyInfoCardProps> = React.memo(function CompanyInfoPostAddressCard (props: CompanyInfoCardProps) {
  const { company, className } = props;
  return (
    <CompanyInfoTableCard title="Utdelningsadress" className={className}>
      <Table className="mb-0">
        <SeamlessTableBody>
          <TableRow value={company.post_co_street} label="C/O-adress" />
          <TableRow value={company.post_street} label="Gatuadress" />
          <TableRow value={company.post_zipcode} label="Postnummer" />
          <TableRow value={company.post_town} label="Ort" />
          <TableRow value={company.post_community_name} label="Kommun" />
          <TableRow value={company.post_region_name} label="Län" />
        </SeamlessTableBody>
      </Table>
    </CompanyInfoTableCard>
  );
});

export const CompanyInfoRegisteredAddressCard: React.FC<CompanyInfoCardProps> = React.memo(function CompanyInfoVisitingAddressCard (props: CompanyInfoCardProps) {
  const { company, className } = props;
  return (
    <CompanyInfoTableCard title="Sätesadress" className={className}>
      <Table className="mb-0">
        <SeamlessTableBody>
          <TableRow value={company.registered_co_street} label="C/O-adress" />
          <TableRow value={company.registered_street} label="Gatuadress" />
          <TableRow value={company.registered_zipcode} label="Postnummer" />
          <TableRow value={company.registered_town} label="Ort" />
          <TableRow value={company.registered_community_name} label="Kommun" />
          <TableRow value={company.registered_region_name} label="Län" />
        </SeamlessTableBody>
      </Table>
    </CompanyInfoTableCard>
  );
});

export const CompanyInfoVisitingAddressCard: React.FC<CompanyInfoCardProps> = React.memo(function CompanyInfoVisitingAddressCard (props: CompanyInfoCardProps) {
  const { company, className } = props;
  return (
    <CompanyInfoTableCard title="Besöksadress" className={className}>
      <Table className="mb-0">
        <SeamlessTableBody>
          <TableRow value={company.visiting_street} label="Gatuadress" />
          <TableRow value={company.visiting_zipcode} label="Postnummer" />
          <TableRow value={company.visiting_town} label="Ort" />
          <TableRow value={company.visiting_community_name} label="Kommun" />
          <TableRow value={company.visiting_region_name} label="Län" />
        </SeamlessTableBody>
      </Table>
    </CompanyInfoTableCard>
  );
});

export const CompanyInfoMomsCard: React.FC<CompanyInfoCardProps> = React.memo(function CompanyInfoMomsCard (props: CompanyInfoCardProps) {
  const { company, className } = props;
  return (
    <CompanyInfoTableCard title="Moms" className={className}>
      <Table className="mb-0">
        <SeamlessTableBody>
          <TableRow value={company.moms_yes_no} label="Momsregistrerad">
            <CompanyFormatters.CompanyMomsDescription value={company} />
          </TableRow>
          <TableRow value={company.moms_start_date} label="Startdatum">
            <DateFormat value={company.moms_start_date} format="YYYY-MM-DD" />
          </TableRow>
          <TableRow value={company.moms_end_date} label="Slutdatum">
            <DateFormat value={company.moms_end_date} format="YYYY-MM-DD" />
          </TableRow>
        </SeamlessTableBody>
      </Table>
    </CompanyInfoTableCard>
  );
});

export const CompanyInfoFtaxCard: React.FC<CompanyInfoCardProps> = React.memo(function CompanyInfoFtaxCard (props: CompanyInfoCardProps) {
  const { company, className } = props;
  return (
    <CompanyInfoTableCard title="F-skatt" className={className}>
      <Table className="mb-0">
        <SeamlessTableBody>
          <TableRow value={company.f_tax_reg_spec} label="F-skatteregistrerad">
            <CompanyFormatters.CompanyFTaxRegSpec value={company.f_tax_reg_spec} />
          </TableRow>
          <TableRow value={company.f_tax_start_date} label="Startdatum">
            <DateFormat value={company.f_tax_start_date} format="YYYY-MM-DD" />
          </TableRow>
          <TableRow value={company.f_tax_end_date} label="Slutdatum">
            <DateFormat value={company.f_tax_end_date} format="YYYY-MM-DD" />
          </TableRow>
        </SeamlessTableBody>
      </Table>
    </CompanyInfoTableCard>
  );
});

interface CompanyInfoUnitCardProps {
  className?: string;
  unit: ICsUnit;
}

export function CompanyInfoUnitCard (props: CompanyInfoUnitCardProps) {
  const { className, unit } = props;
  const title = `${unit.office_name} — ${unit.office_type}`;
  return (
    <CompanyInfoTableCard title={title} className={className}>
      <Table className="mb-0">
        <SeamlessTableBody>
          <TableRow label="Organisationsnummer" value={unit.org_number}>
            <CompanyFormatters.CompanyAnchor value={unit.org_number} />
          </TableRow>
          <TableRow label="CFAR-nummer" value={unit.cfar} />
          <TableRow label="Telefonnummer" value={unit.phone_number} />
          <TableRow label="Fax" value={unit.fax_number} />
          <TableRow label="E-post" value={unit.email_address} />
          <TableRow label="SNI" value={unit.sni_code}>
            {unit.sni_code} — {unit.sni_text}
          </TableRow>
          <TableRow label="Reklamspärr" value={unit.commercial_block_text} />
          <TableRow label="Antal anställda" value={unit.number_of_employees_interval} />
          <TableRow label="C/O-postadress" value={unit.post_co_street} />
          <TableRow label="Postgatuadress" value={unit.post_street } />
          <TableRow label="Postnummer" value={unit.post_zipcode} />
          <TableRow label="Postort" value={unit.post_town } />
          <TableRow label="Kommun" value={unit.post_community_name } />
          <TableRow label="Län" value={unit.post_region_name } />
          <TableRow label="Besöksgatuadress" value={unit.visit_street} />
          <TableRow label="Besökspostnummer" value={unit.visit_zipcode } />
          <TableRow label="Besöksort" value={unit.visit_town } />
          <TableRow label="Besökskommun" value={unit.visiting_community_name } />
          <TableRow label="Besökslän" value={unit.visiting_region_name } />
        </SeamlessTableBody>
      </Table>
    </CompanyInfoTableCard>
  );
}

interface CompanyInfoTableCardProps extends React.PropsWithChildren {
  className?: string;
  title: string;
}

export function CompanyInfoTableCard (props: CompanyInfoTableCardProps) {
  const { title, className, children } = props;
  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between align-items-center border-bottom-0">
        <Card.Title className="mb-0" as="h6">
          {title}
        </Card.Title>
      </Card.Header>
      <div className="table-responsive">
        {children}
      </div>
    </Card>
  );
}

interface TableRowProps {
  label: string;
  value: string | Date | number | null;
  children?: React.ReactNode;
}

function TableRow (props: TableRowProps) {
  const { label, value, children } = props;
  if (!value) return null;
  return (
    <tr>
      <th>{label}</th>
      <td>{children ?? String(value)}</td>
    </tr>
  );
}
