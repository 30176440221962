import React from 'react';
import PageContainer from 'client/components/PageContainer';
import ErrorAlert from 'client/components/ErrorAlert';
import {Link} from 'react-router-dom';

export default function NotFoundPage () {
  return (
    <PageContainer fluid className="p-4">
      <ErrorAlert error="Det finns ingen sida på den här adressen." />
      <Link to="/">&laquo; Gå tillbaks till framsidan</Link>
    </PageContainer>
  );
}
