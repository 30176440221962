import React, {useMemo} from 'react';
import PageContainer from 'client/components/PageContainer';
import { Card } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { IEmailTemplate } from 'client/emailTemplate/types';
import RefreshButton from 'client/buttons/RefreshButton';
import PageHeader from 'client/components/PageHeader';
import {ColumnDefinition} from 'client/table/types';
import { TableSpinningOverlay } from 'client/table/TableUtils';
import DateFormat from 'client/components/DateFormat';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as EmailTemplateFormatters from 'client/emailTemplate/EmailTemplateFormatters';
import EmailTemplateRenderModal from 'client/emailTemplate/EmailTemplateRenderModal';
import {Mail} from 'react-feather';
import TableVirtual from 'client/table/TableVirtual';

const EmailTemplatesTablePage: React.FC = React.memo(function EmailTemplatesTablePage () {

  const query = useQuery<IEmailTemplate[], Error>({
    queryKey: ['/api/email_templates/list'],
  });

  const rows = useMemo(() => {
    return (query.data ?? []).map(row => ({...row, id: String(row.id)}));
  }, [query.data]);

  return (
    <PageContainer fluid="lg">
      <Helmet>
        <title>E-postmallar</title>
      </Helmet>
      <PageHeader>E-postmallar</PageHeader>
      <ErrorAlert className="my-3" error={query.error} />
      <Card>
        <Card.Header className="d-flex flex-wrap gap-3 justify-content-end">
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="px-2"
          />
        </Card.Header>
        <TableSpinningOverlay isLoading={query.isRefetching}>
          <div className="table-responsive">
            <TableVirtual
              className="mb-0 align-middle"
              rows={rows}
              columns={columns}
              isInitialLoading={query.isLoading}
            />
          </div>
        </TableSpinningOverlay>
      </Card>
    </PageContainer>
  );
});

export default EmailTemplatesTablePage;

const columns: ColumnDefinition[] = [
  columnDefs.cell(['id', 'ID'], props => <EmailTemplateFormatters.EmailTemplateAnchor value={props.row} />),
  columnDefs.simple(['subject', 'Ämne']),
  columnDefs.cell(['updated_at', 'Uppdaterad'], props => (
    <DateFormat value={props.row.updated_at} format="YYYY-MM-DD HH:mm" />
  )),
  columnDefs.actions(props => (
    <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
      <ModalOpeningButton
        variant="outline-primary"
        className="d-flex gap-1 align-items-center px-1 py-0"
        Modal={EmailTemplateRenderModal as React.FC}
        modalProps={{emailTemplateId: props.row.id}}
        size="sm"
      >
        <Mail size={14} />
        Rendera
      </ModalOpeningButton>
    </div>
  )),
];
