import React from 'react';
import { Table, Card, Spinner } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import NumberFormat from 'client/components/NumberFormat';
import { ICsImported } from 'client/cs/types';
import RefreshButton from 'client/buttons/RefreshButton';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import AgeWarningAlert from 'client/components/AgeWarningAlert';

interface ResponseData {
  last: ICsImported | null;
  last_inc: ICsImported | null;
  last_base: ICsImported | null;
}

interface DashboardCreditsafeStatisticCardsProps {
  className?: string;
}

function DashboardCreditsafeStatisticCards (props: DashboardCreditsafeStatisticCardsProps) {
  const { className } = props;

  const query = useQuery<ResponseData>({
    queryKey: ['/api/dashboard/statistics/cs_imports'],
    placeholderData: keepPreviousData,
  });

  const data = query.data;

  return (
    <Card className={className}>
      <Card.Header className="p-3">
        <Card.Title className="mb-0 d-flex justify-content-between" style={{fontSize: '0.9rem'}}>
          <div className="d-flex align-items-center gap-2">
            Senaste import av Creditsafe-data
            {(query.isLoading || query.isRefetching) && (
              <Spinner size="sm" />
            )}
          </div>
          <RefreshButton
            size="sm"
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="px-1"
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      {data && (
        <div className="table-responsive">
          <Table size="sm" className="mb-0 no-border-bottom">
            <tbody>
              {data?.last_inc && (
                <tr>
                  <th>Senaste inkrementell fil</th>
                  <td>{data?.last_inc?.zip_name}</td>
                  <td>
                    <AgeWarningAlert
                      date={data.last_inc?.zip_date}
                      warningAge={2}
                      dangerAge={3}
                    />
                  </td>
                  <td>
                    <NumberFormat value={countRows(data.last_inc as ICsImported)} />
                    {' '}rader
                  </td>
                </tr>
              )}
              {data?.last_base && (
                <tr>
                  <th>Senaste basfil</th>
                  <td>{data?.last_base?.zip_name}</td>
                  <td>
                    <AgeWarningAlert
                      date={data.last_base?.zip_date}
                      warningAge={31}
                      dangerAge={33}
                    />
                  </td>
                  <td>
                    <NumberFormat value={countRows(data.last_base as ICsImported)} />
                    {' '}rader
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </Card>
  );
}

export default React.memo(DashboardCreditsafeStatisticCards);

function countRows (row: ICsImported): number {
  const { result } = row;
  if (!result) return 0;
  let count = 0;
  for (const key in result) {
    const item = result[key];
    count += item.rowCount;
  }
  return count;
}
