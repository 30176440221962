import React, { useMemo, useState } from 'react';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import { Form, Modal, Tab, Table, Tabs } from 'react-bootstrap';
import LoadingButton from 'client/buttons/LoadingButton';
import LoadingPromiseButton from 'client/buttons/LoadingPromiseButton';
import IdProvider from 'client/components/IdProvider';
import axios from 'client/axios';
import { useMutation } from '@tanstack/react-query';
import { ICsBasic } from 'client/cs/types';
import { AxiosResponse } from 'axios';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import ErrorAlert from 'client/components/ErrorAlert';

export default React.memo(CompanyBatchSearchButton) as React.FC<CompanyBatchSearchButtonProps>;

interface CompanyBatchSearchButtonProps extends React.PropsWithChildren {
  title?: string;
  onSelect?: (orgNumbers: string[]) => void | Promise<any>;
  searchBatchUrl?: string;
  disabled?: boolean;
}

function CompanyBatchSearchButton (props: CompanyBatchSearchButtonProps) {
  const {
    children,
    title,
    onSelect,
    searchBatchUrl = '/api/company/searchBatch',
    disabled = false,
  } = props;

  const modalProps = {searchBatchUrl, onSelect};

  return (
    <ModalOpeningButton
      title={title}
      Modal={CompanyBatchSearchModal}
      modalProps={modalProps}
      variant="outline-primary"
      disabled={disabled}
    >
      {children}
    </ModalOpeningButton>
  );
}

interface CompanyBatchSearchModalProps {
  object: any;
  show: boolean;
  onHide: () => void;
  onExited: () => void;
  searchBatchUrl: string;
  onSelect?: (orgNumbers: string[]) => void | Promise<any>;
}

type SearchMutationItem = Pick<ICsBasic, 'org_number' | 'company_name' | 'status_text_high'> | null;
type SearchMutationResponse = SearchMutationItem[];

interface ReviewItem {
  selected: boolean;
  orgNumber: string;
  result: SearchMutationItem;
}

function CompanyBatchSearchModal (props: CompanyBatchSearchModalProps) {
  const {
    show,
    onHide,
    onExited,
    searchBatchUrl,
    onSelect:onSelectOrgNumbers,
  } = props;

  const [tab, setTab] = useState<string>('input');

  const searchMutation = useMutation<SearchMutationResponse, Error, string[]>({
    mutationFn: async orgNumbers => {
      const response = await axios.post<any, AxiosResponse<SearchMutationResponse>>(searchBatchUrl, {
        org_numbers: orgNumbers,
      });

      setTab('review');

      setReview(orgNumbers.map(orgNumber => {
        const result = response.data.find(item => item?.org_number === orgNumber);
        return {
          selected: Boolean(result),
          orgNumber,
          result: result || null,
        };
      }));

      return response.data;
    },
  });

  const onSelectTab = (tab: string | null) => {
    if (!tab) return;
    setTab(tab);
  };

  const onSubmitInput = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    searchMutation.mutateAsync(orgNumbers);
  };

  const onSubmitReview = (ev?: any): Promise<any> => {
    ev?.preventDefault?.();
    const orgNumbers = review.filter(item => item.selected).map(item => item.orgNumber);
    const promise = onSelectOrgNumbers?.(orgNumbers) ?? Promise.resolve(true);
    promise.finally(() => {
      onHide();
    });
    return promise;
  };

  const [text, setText] = useState<string>('');
  const [orgNumbers, setOrgNumbers] = useState<string[]>([]);
  const [lineCount, setLineCount] = useState<null | number>(null);

  const onChangeText = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = ev.target.value;
    setText(value);

    if (!value) {
      setLineCount(null);
      setOrgNumbers([]);
      return;
    }

    const lines = value.split(/\n/);
    setLineCount(lines.length);

    const orgNumbers = lines.map(line => line.replace(/\D/g, '')).filter(v => v);
    setOrgNumbers(orgNumbers);
  };

  const [review, setReview] = useState<ReviewItem[]>([]);
  const onSelectItem = (orgNumber: string, selected: boolean) => {
    setReview(prevReview => prevReview.map(item => {
      if (item?.orgNumber !== orgNumber) return item;
      return {...item, selected};
    }));
  };

  const reviewSelectedOrgNumberCount = useMemo(() => {
    return review.reduce((sum, item) => sum + ((item.selected && item.result) ? 1 : 0), 0);
  }, [review]);

  return (
    <Modal show={show} onHide={onHide} onExited={onExited} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Lägg till flera företag
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={tab} onSelect={onSelectTab}>

          <Tab eventKey="input" title="1) Klistra in">
            <form onSubmit={onSubmitInput}>
              <IdProvider>
                {id => (
                  <Form.Group className="mt-3">
                    <Form.Label htmlFor={id}>
                      Klistra in ett organisationsnummer per rad i fältet nedan.
                    </Form.Label>
                    <Form.Control
                      id={id}
                      as="textarea"
                      rows={5}
                      required
                      value={text}
                      onChange={onChangeText}
                    />
                  </Form.Group>
                )}
              </IdProvider>
              <div className="d-flex justify-content-end mt-3 align-items-center gap-2">
                {lineCount !== null && (
                  <span className="small">
                    {orgNumbers.length} av {lineCount} rader är giltiga
                  </span>
                )}
                <LoadingButton
                  size="sm"
                  type="submit"
                  disabled={!orgNumbers.length}
                  isLoading={searchMutation.isPending}
                >
                  Sök och gå vidare till väljaren
                </LoadingButton>
              </div>
              <ErrorAlert className="mt-3 mb-0" error={searchMutation.error} />
            </form>
          </Tab>

          <Tab eventKey="review" title="2) Välj sökresultat" disabled={!searchMutation.isSuccess}>
            <form onSubmit={onSubmitReview}>
              <Table size="sm" bordered className="mt-4 small">
                <thead>
                  <tr>
                    <th>
                    </th>
                    <th>Söknummer</th>
                    <th>Träff</th>
                  </tr>
                </thead>
                <tbody>
                  {review.map(item => (
                    <ReviewItemTableRow
                      key={item.orgNumber}
                      item={item}
                      onSelect={onSelectItem}
                    />
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end mt-3 align-items-center gap-2">
                {!reviewSelectedOrgNumberCount && (
                  <span className="small text-danger">
                    Det finns inga valda giltiga företag
                  </span>
                )}
                <LoadingPromiseButton
                  size="sm"
                  type="submit"
                  disabled={!reviewSelectedOrgNumberCount}
                  onClick={onSubmitReview}
                >
                  Lägg till {reviewSelectedOrgNumberCount} organisationsnummer
                </LoadingPromiseButton>
              </div>
            </form>
          </Tab>

        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

interface ReviewItemTableRowProps {
  item: ReviewItem;
  onSelect: (orgNumber: string, selected: boolean) => void;
}

function ReviewItemTableRow (props: ReviewItemTableRowProps) {
  const { item, onSelect } = props;

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(item.orgNumber, ev.target.checked);
  };

  return (
    <tr>
      <td>
        <div className="d-flex justify-content-center">
          <Form.Check.Input
            disabled={!Boolean(item.result)}
            onChange={onChange}
            checked={item.selected}
          />
        </div>
      </td>
      <td>
        {item.orgNumber}
      </td>
      <td>
        {item.result ? (
          <span className="d-flex gap-2 align-items-center">
            <span>
              {item.result.company_name ?? item.result.org_number}
            </span>
            <CompanyFormatters.StatusLabel value={item.result.status_text_high} />
          </span>
        ) : (
          <span>-</span>
        )}
      </td>
    </tr>
  );
}
