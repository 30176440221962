import * as filterDefs from 'client/table/commonFilterDefinitions';
import { numberOfEmployeesIntervals } from 'client/accounts/types';
import BranchTableFilterModalBody from 'client/branches/BranchTableFilterModalBody';
import {FilterDefinition} from 'client/table/types';
import * as csStrings from 'client/cs/strings';

export const legalgroupCodeSelect = () => filterDefs.select(
  ['legalgroup_code', 'Bolagsform'],
  [
    {value: 'AB', label: 'Aktiebolag'},
    {value: 'EF', label: 'Enskild firma'},
    {value: 'HB/KB', label: 'Handels/kommanditbolag'},
    {value: 'OVR', label: 'Övrigt'},
  ],
  {extraProps: {placeholder: 'Oavsett bolagsform'}},
);

export const turnoverIntervalSelect = () => filterDefs.select(
  ['turnover_interval', 'Omsättningsintervall'],
  [
    {value: '> 9999999 tkr'},
    {value: '5000000 - 9999999 tkr'},
    {value: '1000000 - 4999999 tkr'},
    {value: '500000 - 999999 tkr'},
    {value: '100000 - 499999 tkr'},
    {value: '50000 - 99999 tkr'},
    {value: '20000 - 49999 tkr'},
    {value: '10000 - 19999 tkr'},
    {value: '5000 - 9999 tkr'},
    {value: '2500 - 4999 tkr'},
    {value: '1000 - 2499 tkr'},
    {value: '750 - 999 tkr'},
    {value: '500 - 749 tkr'},
    {value: '400 - 499 tkr'},
    {value: '300 - 399 tkr'},
    {value: '200 - 299 tkr'},
    {value: '150 - 199 tkr'},
    {value: '100 - 149 tkr'},
    {value: '50 - 99 tkr'},
    {value: '1 - 49 tkr'},
    {value: '< 1 tkr'},
  ],
  {extraProps: {placeholder: 'Oavsett omsättningsintervall'}},
);

export const nbrEmployeesIntervalSelect = () => filterDefs.select(
  ['nbr_employees_interval', 'Anställda intervall'],
  numberOfEmployeesIntervals.map(interval => ({value: interval})),
  {extraProps: {placeholder: 'Oavsett anställda intervall'}},
);

export const branchModal = () => filterDefs.simpleModal(
  ['branch_id', 'Bransch'], BranchTableFilterModalBody,
  {extraProps: {buttonLabel: 'Välj bransch', modalTitle: 'Välj bransch'}},
);

// returns all filters compatible with CompanyController
// TODO many of these could benefit from proper select lists
export const companyControllerList = (): FilterDefinition<any>[] => [
  filterDefs.input(['search_term', 'Sökterm']),
  filterDefs.input(csStrings.csBasic.label('sni_code')),
  filterDefs.input(csStrings.csBasic.label('sni_text')),
  legalgroupCodeSelect(),
  turnoverIntervalSelect(),
  nbrEmployeesIntervalSelect(),
  branchModal(),
  filterDefs.input(csStrings.csBasic.label('accountant_reservation')),
  filterDefs.input(csStrings.csBasic.label('complete_ef_pnr')),
  filterDefs.input(csStrings.csBasic.label('f_tax_dereg_reason_code')),
  filterDefs.input(csStrings.csBasic.label('f_tax_reg')),
  filterDefs.input(csStrings.csBasic.label('f_tax_reg_spec')),
  filterDefs.input(csStrings.csBasic.label('f_tax_yes_no')),
  filterDefs.input(csStrings.csBasic.label('legalgroup_text')),
  filterDefs.input(csStrings.csBasic.label('moms_reg')),
  filterDefs.input(csStrings.csBasic.label('moms_yes_no')),
  filterDefs.input(csStrings.csBasic.label('number_of_units')),
  filterDefs.input(csStrings.csBasic.label('org_number')),
  filterDefs.input(csStrings.csBasic.label('post_co_street')),
  filterDefs.input(csStrings.csBasic.label('post_community_code')),
  filterDefs.input(csStrings.csBasic.label('post_community_name')),
  filterDefs.input(csStrings.csBasic.label('post_region_code')),
  filterDefs.input(csStrings.csBasic.label('post_region_name')),
  filterDefs.input(csStrings.csBasic.label('post_street')),
  filterDefs.input(csStrings.csBasic.label('post_town')),
  filterDefs.input(csStrings.csBasic.label('post_zipcode')),
  filterDefs.input(csStrings.csBasic.label('registered_co_street')),
  filterDefs.input(csStrings.csBasic.label('registered_community_code')),
  filterDefs.input(csStrings.csBasic.label('registered_community_name')),
  filterDefs.input(csStrings.csBasic.label('registered_region_code')),
  filterDefs.input(csStrings.csBasic.label('registered_region_name')),
  filterDefs.input(csStrings.csBasic.label('registered_street')),
  filterDefs.input(csStrings.csBasic.label('registered_town')),
  filterDefs.input(csStrings.csBasic.label('registered_zipcode')),
  filterDefs.input(csStrings.csBasic.label('status_code')),
  filterDefs.input(csStrings.csBasic.label('status_text_detailed')),
  filterDefs.input(csStrings.csBasic.label('status_text_high')),
  filterDefs.input(csStrings.csBasic.label('visiting_community_code')),
  filterDefs.input(csStrings.csBasic.label('visiting_community_name')),
  filterDefs.input(csStrings.csBasic.label('visiting_region_code')),
  filterDefs.input(csStrings.csBasic.label('visiting_region_name')),
  filterDefs.input(csStrings.csBasic.label('visiting_street')),
  filterDefs.input(csStrings.csBasic.label('visiting_town')),
  filterDefs.input(csStrings.csBasic.label('visiting_zipcode')),
];
