import React, { useMemo, useCallback, ChangeEventHandler } from 'react';
import { produceSetMap } from 'client/utils/react';
import { Form } from 'react-bootstrap';
import { Zap, Trash } from 'react-feather';
import { IReportQueueWithRelations } from 'client/reportQueue/types';
import LoadingButton from 'client/buttons/LoadingButton';
import Pluralizer from 'client/components/Pluralizer';
import Pagination from 'client/table/TablePagination';
import { UseTablePagination } from 'client/hooks/useTablePagination';
import { TableCardFooter, TableSpinningOverlay } from 'client/table/TableUtils';
import { pick } from 'lodash';
import {
  IReportTableBatchForm,
  ReportQueueTablePageState,
} from 'client/reportQueue/ReportQueueTablePage';
import RoleGuard from 'client/guards/RoleGuard';
import classNames from 'classnames';
import TableVirtual, { TableVirtualProps } from 'client/table/TableVirtual';
import { UseTableSelectRows } from 'client/hooks/useTableSelectRows';

type TReportTableVariant = 'new' | 'waiting' | 'error' | 'ready_not_done' | 'ready_done';

export interface ReportQueueTableProps extends Pick<TableVirtualProps, 'columnsVisible' | 'columns'> {
  variant: TReportTableVariant;
  state: ReportQueueTablePageState;
  setStateMap: ReturnType<typeof produceSetMap>;
  isExecutingBatch: boolean;
  isExecutingBatchDelete: boolean;
  onExecuteBatch: (reportQueueIds: number[], form: IReportTableBatchForm) => void;
  onExecuteBatchDelete: (reportQueueIds: number[]) => void;
  onReportQueueDeleted: (id: number) => void;
  onReportQueueUpdated: (id: number, updatedReportQueue: IReportQueueWithRelations) => void;
  list: IReportQueueWithRelations[];
  isLoading: boolean;
  isInitialLoading: boolean;
  tablePagination?: UseTablePagination;
  tableSelectRows: UseTableSelectRows;
}

const ReportTable: React.FC<ReportQueueTableProps> = React.memo(function ReportTable (props: ReportQueueTableProps) {
  const {
    state,
    setStateMap,
    variant,
    list,
    onExecuteBatchDelete,
    onExecuteBatch,
    isLoading,
    isInitialLoading,
    tablePagination,
    onReportQueueUpdated, // eslint-disable-line
    onReportQueueDeleted, // eslint-disable-line
    isExecutingBatch,
    isExecutingBatchDelete,
    tableSelectRows,
    columns,
    columnsVisible,
  } = props;

  const {
    batchForm,
    isExpanded,
  } = state;

  const onChangeBatchFormCheckbox: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
    const { name, checked } = ev.target;
    setStateMap({[`batchForm.${name}`]: checked});
  }, [setStateMap]);

  const onChangeBatchFormData: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
    const { name, value } = ev.target;
    setStateMap({[`batchForm.data.${name}`]: value});
  }, [setStateMap]);

  const hasChosenSomethingInBatchForm = useMemo(() => {
    return Object.entries(batchForm).some(([, value]) => Boolean(value));
  }, [batchForm]);

  const onClickSubmitBatch = () => {
    const localBatchForm = batchFormToLocal(batchForm, variant);
    onExecuteBatch(tableSelectRows.selectedRowIds.map(str => parseInt(str as string, 10)), localBatchForm);
  };

  const onClickDeleteBatch = () => {
    onExecuteBatchDelete(tableSelectRows.selectedRowIds.map(str => parseInt(str as string, 10)));
  };

  const rows = useMemo(() => {
    if (!Array.isArray(list)) return [];
    return (list || []).map(row => ({...row, id: String(row.id)}));
  }, [list]);

  const batchFormSubmitDisabled = tableSelectRows.selectedRowCount < 1 || !hasChosenSomethingInBatchForm || isExecutingBatchDelete || isExecutingBatch;

  return (
    <div>
      <RoleGuard role={['admin', 'coordinator']}>
        <div className="border-bottom p-3 d-flex flex-wrap align-items-center gap-2">
          <div>
            Med {tableSelectRows.selectedRowCount}{' '}
            <Pluralizer
              count={tableSelectRows.selectedRowCount}
              zero="markerade rader"
              one="markerad rad"
              more="markerade rader"
            />:
          </div>
          <>
            {['ready_not_done', 'ready_done'].includes(variant) && (
              <>
                {variant === 'ready_not_done' && (
                  <label className="border rounded py-1 px-2 small">
                    <Form.Check
                      className="me-2"
                      inline
                      name="setIsDone"
                      checked={batchForm.setIsDone}
                      onChange={onChangeBatchFormCheckbox}
                    />
                    Flytta till "Hanterade"
                  </label>
                )}
                {variant === 'ready_done' && (
                  <label className="border rounded py-1 px-2 small">
                    <Form.Check
                      className="me-2"
                      inline
                      name="setIsNotDone"
                      checked={batchForm.setIsNotDone}
                      onChange={onChangeBatchFormCheckbox}
                    />
                    Flytta till "Ej hanterade"
                  </label>
                )}
                <label className="border rounded py-1 px-2 small">
                  <Form.Check
                    className="me-2"
                    inline
                    name="download"
                    checked={batchForm.download}
                    onChange={onChangeBatchFormCheckbox}
                  />
                  Ladda hem
                </label>
                <label className="border rounded py-1 px-2 small">
                  <Form.Check
                    className="me-2"
                    inline
                    name="emailCustomer"
                    checked={batchForm.emailCustomer}
                    onChange={onChangeBatchFormCheckbox}
                  />
                  Maila kund
                </label>
              </>
            )}
            {!['ready_done', 'ready_not_done'].includes(variant) && (
              <>
                <label className="border rounded py-1 px-2 small">
                  <Form.Check
                    className="me-2"
                    inline
                    name="setProductionDate"
                    checked={batchForm.setProductionDate}
                    onChange={onChangeBatchFormCheckbox}
                  />
                  Ändra prod. datum
                </label>
                {batchForm.setProductionDate && (
                  <Form.Group>
                    <Form.Control
                      size="sm"
                      className="me-2"
                      name="production_date"
                      value={batchForm?.data?.production_date ?? ''}
                      type="date"
                      placeholder="Ange ett produktionsdatum"
                      required
                      onChange={onChangeBatchFormData}
                    />
                  </Form.Group>
                )}
              </>
            )}
            <LoadingButton
              size="sm"
              variant="outline-primary"
              className="d-flex align-items-center"
              disabled={batchFormSubmitDisabled}
              onClick={onClickSubmitBatch}
              isLoading={isExecutingBatch}
            >
              <Zap size={14} />Utför
            </LoadingButton>
          </>
          <LoadingButton
            size="sm"
            variant="outline-danger"
            className="d-flex align-items-center"
            disabled={tableSelectRows.selectedRowCount < 1 || isExecutingBatchDelete || isExecutingBatch}
            onClick={onClickDeleteBatch}
            isLoading={isExecutingBatchDelete}
          >
            <Trash size={14} />Radera rader
          </LoadingButton>
        </div>
      </RoleGuard>
      <TableSpinningOverlay isLoading={isLoading}>
        <div className="table-responsive text-nowrap">
          <TableVirtual
            className={classNames('mb-0', {'small': isExpanded})}
            rows={rows}
            columns={columns}
            isInitialLoading={isInitialLoading}
            columnsVisible={columnsVisible}
            size={isExpanded ? 'sm' : undefined}
          />
        </div>
      </TableSpinningOverlay>
      <TableCardFooter>
        <p className="mb-0">
          Totalt {tablePagination?.totalCountOfRows || list.length}{' '}
          <Pluralizer
            count={tablePagination?.totalCountOfRows || list.length}
            zero="rapporter"
            one="rapport"
            more="rapporter"
          /> i denna kö
        </p>
        {tablePagination && (
          <Pagination withRowsPerPage {...tablePagination} />
        )}
      </TableCardFooter>
    </div>
  );
});
export default ReportTable;

// hackish function to make sure we don't call any batch actions that are not valid for
// a certain table variant
function batchFormToLocal (batchForm: IReportTableBatchForm, variant: TReportTableVariant): IReportTableBatchForm {
  const keysByVariant = {
    new: ['setProductionDate', 'data'],
    waiting: ['setProductionDate', 'data'],
    error: ['setProductionDate', 'data'],
    ready_not_done: ['setIsDone', 'download', 'emailCustomer'],
    ready_done: ['setIsNotDone', 'download', 'emailCustomer'],
  };
  return pick(batchForm, keysByVariant[variant]);
}
