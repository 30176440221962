import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import { ModalOpeningButtonModalProps } from 'client/buttons/ModalOpeningButton';

export interface TableFilterSimpleModalBodyProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

interface TableFilterSimpleModalProps extends ModalOpeningButtonModalProps {
  ModalBody: React.ComponentType<TableFilterSimpleModalBodyProps>;
  value: string;
  onChangeValue: (newValue: string) => void;
  title: string;
}

export default function TableFilterSimpleModal (props: TableFilterSimpleModalProps) {
  const {
    show,
    onHide,
    onExited,
    value:outerValue,
    onChangeValue,
    ModalBody,
    title,
  } = props;

  const [value, setValue] = useState<string>(outerValue);

  const onSaveAndHide = () => {
    onChangeValue(value);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          {title}
        </Modal.Title>
      </Modal.Header>
      <ModalBody value={value} setValue={setValue} />
      <Modal.Footer>
        <div className="d-flex flex-wrap gap-2">
          <Button variant="outline-secondary" onClick={onHide}>
            Stäng
          </Button>
          <Button onClick={onSaveAndHide} variant="outline-primary">
            Spara och stäng
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
