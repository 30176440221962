import React, { useCallback } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import RefreshButton from 'client/buttons/RefreshButton';
import { preventDefaultSubmit } from 'client/utils/form';
import { IFilterParams } from 'client/reportQueue/ReportQueueTablePage';
import {
  ReportQueueLanguageEnum,
  ReportQueueReportTypeEnum,
} from 'client/reportQueue/types';
import TableSettingsButton, { TableSettingsButtonProps } from 'client/table/TableSettingsButton';

type FilterVisibility = Partial<Record<keyof IFilterParams, boolean>>;

interface ReportQueueTableFilterFormProps extends TableSettingsButtonProps {
  filterParams: IFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<IFilterParams>>;
  defaultFilterParams: IFilterParams;
  filterVisibility: FilterVisibility;
  setFilterVisibility: React.Dispatch<React.SetStateAction<FilterVisibility>>;
  isEditingFilterVisibility: boolean;
  setIsEditingFilterVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  columnDefinitions: any[]; // FIXME any wtf
  onRefetch: () => void;
  onToggleIsExpanded: () => void;
  isLoading: boolean;
  isExpanded: boolean;
}

const ReportQueueTableFilterForm: React.FC<ReportQueueTableFilterFormProps> = React.memo(function ReportQueueTableFilterForm (props: ReportQueueTableFilterFormProps) {
  const {
    isLoading,
    onRefetch,
    filterParams,
    defaultFilterParams,
    setFilterParams,
    filterVisibility,
    setFilterVisibility,
    isEditingFilterVisibility,
    setIsEditingFilterVisibility,
    onToggleIsExpanded,
    isExpanded,
    columnDefinitions,
    columnsVisible,
    setColumnsVisible,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({...filterParams, [name]: value});
  }, [filterParams, setFilterParams]);

  const onClickReset = useCallback(() => {
    setFilterParams({...defaultFilterParams});
  }, [filterParams, setFilterParams]);

  const onChangeFilterVisibility = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = ev.target;
    const nameWithoutActive = name.split('_').slice(0, -1).join('_');
    setFilterVisibility({...filterVisibility, [nameWithoutActive]: checked});
  };

  const onToggleEditFilterVisibility = () => {
    setIsEditingFilterVisibility(!isEditingFilterVisibility);
  };

  return (
    <Form onSubmit={preventDefaultSubmit}>
      <div className="border-bottom d-flex gap-2 p-2 flex-wrap">
        <label className="border rounded py-1 px-2">
          <Form.Check
            className="me-2"
            inline
            checked={isEditingFilterVisibility}
            onChange={onToggleEditFilterVisibility}
          />
          Visa alla filter
        </label>
        <label className="border rounded py-1 px-2">
          <Form.Check
            className="me-2"
            inline
            checked={isExpanded}
            onChange={() => onToggleIsExpanded()}
          />
          Expanderad tabell
        </label>
        <TableSettingsButton
          columnDefinitions={columnDefinitions}
          columnsVisible={columnsVisible}
          setColumnsVisible={setColumnsVisible}
          disabled={!isExpanded}
        />
        <Button
          title="Ta bort alla filtreringar"
          onClick={onClickReset}
          size="sm"
          variant="outline-primary"
          className="d-flex align-items-center justify-content-center"
          disabled={isLoading}
        >
          Återställ filter
        </Button>
        <RefreshButton onClick={onRefetch} disabled={isLoading} />
      </div>
      <div className="d-flex flex-wrap p-2 gap-2">
        <FilterFormFieldContainer
          onChangeFilterVisibility={onChangeFilterVisibility}
          filterVisibility={filterVisibility}
          editing={isEditingFilterVisibility}
          name="search_term"
        >
          <Form.Control
            isValid={Boolean(filterParams.search_term)}
            name="search_term"
            onChange={onChange}
            placeholder="Oavsett sökterm"
            value={filterParams.search_term ?? ''}
          />
        </FilterFormFieldContainer>
        <FilterFormFieldContainer
          onChangeFilterVisibility={onChangeFilterVisibility}
          filterVisibility={filterVisibility}
          editing={isEditingFilterVisibility}
          name="order_number"
        >
          <Form.Control
            isValid={Boolean(filterParams.order_number)}
            name="order_number"
            onChange={onChange}
            placeholder="Oavsett ordernummer"
            value={filterParams.order_number ?? ''}
          />
        </FilterFormFieldContainer>
        <FilterFormFieldContainer
          onChangeFilterVisibility={onChangeFilterVisibility}
          filterVisibility={filterVisibility}
          editing={isEditingFilterVisibility}
          name="report_type"
        >
          <Form.Select
            isValid={Boolean(filterParams.report_type)}
            name="report_type"
            onChange={onChange}
            value={filterParams.report_type ?? ''}
          >
            <option value="">Oavsett typ av rapport</option>
            {Object.entries(ReportQueueReportTypeEnum).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </Form.Select>
        </FilterFormFieldContainer>
        <FilterFormFieldContainer
          onChangeFilterVisibility={onChangeFilterVisibility}
          filterVisibility={filterVisibility}
          editing={isEditingFilterVisibility}
          name="language"
        >
          <Form.Select
            isValid={Boolean(filterParams.language)}
            name="language"
            onChange={onChange}
            value={filterParams.language ?? ''}
          >
            <option value="">Oavsett språk</option>
            {Object.entries(ReportQueueLanguageEnum).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </Form.Select>
        </FilterFormFieldContainer>
        <FilterFormFieldContainer
          onChangeFilterVisibility={onChangeFilterVisibility}
          filterVisibility={filterVisibility}
          editing={isEditingFilterVisibility}
          name="customer_id_exists"
        >
          <Form.Select
            isValid={Boolean(filterParams.customer_id_exists)}
            name="customer_id_exists"
            onChange={onChange}
            value={String(filterParams.customer_id_exists ?? '')}
          >
            <option value="">Oavsett kund</option>
            <option value="false">Ingen kund (privat)</option>
            <option value="true">Sparad på kund</option>
          </Form.Select>
        </FilterFormFieldContainer>
        <FilterFormFieldContainer
          onChangeFilterVisibility={onChangeFilterVisibility}
          filterVisibility={filterVisibility}
          editing={isEditingFilterVisibility}
          name="delivery"
        >
          <Form.Select
            isValid={Boolean(filterParams.delivery)}
            name="delivery"
            onChange={onChange}
            value={String(filterParams.delivery ?? '')}
          >
            <option value="">Oavsett leveransval</option>
            <option value="email">Endast digitalt</option>
            <option value="printed">Endast tryckt</option>
            <option value="email_and_printed">Tryckt och digitalt</option>
          </Form.Select>
        </FilterFormFieldContainer>
      </div>
    </Form>
  );
});
export default ReportQueueTableFilterForm;

interface FilterFormInputGroupProps extends React.PropsWithChildren {
  name: string;
  editing: boolean;
  filterVisibility: {[key: string]: boolean};
  onChangeFilterVisibility: React.ChangeEventHandler;
}

function FilterFormFieldContainer (props: FilterFormInputGroupProps) {
  const { name, editing, filterVisibility, onChangeFilterVisibility, children } = props;
  if (!editing && !filterVisibility[name]) return null;
  return (
    <Form.Group>
      <InputGroup>
        {editing && (
          <InputGroup.Checkbox
            checked={filterVisibility[name]}
            onChange={onChangeFilterVisibility}
            className="mt-0"
            name={name + '_active'}
          />
        )}
        {children}
      </InputGroup>
    </Form.Group>
  );
}
