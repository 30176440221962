import React from 'react';
import SendEmailButton, { SendEmailButtonProps } from 'client/email/SendEmailButton';

interface SendCustomerEmailButtonProps extends Omit<SendEmailButtonProps, 'objectType' | 'objectId'> {
  emailTemplateIdOrType: 'resetPassword' | 'notifyNewReport' | number;
  customerId: string;
  onSuccess: () => void;
}

const SendCustomerEmailButtonProps: React.FC<SendCustomerEmailButtonProps> = React.memo(function SendCustomerEmailButton (props: SendCustomerEmailButtonProps) {
  const { customerId, ...restOfProps } = props;


  return (
    <SendEmailButton
      objectType="customer"
      objectId={customerId}
      {...restOfProps}
    />
  );
});
export default SendCustomerEmailButtonProps;
