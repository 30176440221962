export const csBasic = {
  columnName: (columnName: string): string => {
    switch (columnName) {
      default: return columnName;
      case 'org_number': return 'Orgnr';
      case 'company_name': return 'Bolagsnamn';
      case 'registered_town': return 'Sätets adress, ort';
      case 'registered_co_street': return 'Sätets adress, C/O-adress';
      case 'registered_street': return 'Sätets adress, gatuadress';
      case 'registered_zipcode': return 'Sätets adress, postnummer';
      case 'registered_community_code': return 'Sätets adress, kommunkod';
      case 'registered_community_name': return 'Sätets adress, kommun';
      case 'registered_region_code': return 'Sätets adress, länskod';
      case 'registered_region_name': return 'Sätets adress, län';
      case 'post_co_street': return 'Utdelningsadress, C/O-adress';
      case 'post_street': return 'Utdelningsadress, gatuadress';
      case 'post_zipcode': return 'Utdelningsadress, postnummer';
      case 'post_town': return 'Utdelningsadress, ort';
      case 'post_community_code': return 'Utdelningsadress, kommunkod';
      case 'post_community_name': return 'Utdelningsadress, kommun';
      case 'post_region_code': return 'Utdelningsadress, länskod';
      case 'post_region_name': return 'Utdelningsadress, län';
      case 'visiting_street': return 'Besöksadress, gatuadress';
      case 'visiting_zipcode': return 'Besökadress, postnummer';
      case 'visiting_town': return 'Besöksadress, ort';
      case 'visiting_community_code': return 'Besöksadress, kommunkod';
      case 'visiting_community_name': return 'Besöksadress, kommun';
      case 'visiting_region_code': return 'Besöksadress, länskod';
      case 'visiting_region_name': return 'Besöksadress, län';
      case 'status_date': return 'Datum för senaste status kod';
      case 'status_code': return 'Bolagets statuskod (kod)';
      case 'status_text_high': return 'Bolagets status (text)';
      case 'status_text_detailed': return 'Bolagets status (detaljerad)';
      case 'complete_ef_pnr': return 'Fullständigt personnummer för EF';
      case 'phone_number': return 'Telefonnummer';
      case 'fax_number': return 'Faxnummer';
      case 'orgnr_status_affected': return 'Orgnr för bolag som händelse avser, ex. fusion';
      case 'company_formed_date': return 'Datum då bolagets bildades';
      case 'registration_date': return 'Registreringsdatum';
      case 'deregistration_date': return 'Avregistreringsdatum';
      case 'legalgroup_code': return 'Bolagsform (kod)';
      case 'legalgroup_text': return 'Bolagsform (text)';
      case 'f_tax_reg': return 'F-skattstatus';
      case 'f_tax_yes_no': return 'F-skatt';
      case 'f_tax_reg_spec': return 'F-skatthistorik';
      case 'f_tax_start_date': return 'Startdatum F-Skatt';
      case 'f_tax_end_date': return 'Slutdatum F-Skatt';
      case 'f_tax_dereg_reason_code': return 'Orsak till avregistrering av F-skatt';
      case 'moms_reg': return 'Momsstatus';
      case 'moms_yes_no': return 'Moms';
      case 'moms_start_date': return 'Startdatum moms';
      case 'moms_end_date': return 'Slutdatum moms';
      case 'sni_code': return 'SNI-kod';
      case 'sni_text': return 'SNI-text';
      case 'accountant_reservation': return 'Revisorsförbehåll';
      case 'date_accountant_reservation': return 'Datum för revisorsförbehåll';
      case 'number_of_units': return 'Antal arbetsställen';
      case 'top_director_name': return 'Högsta befattningshavares namn';
      case 'top_director_function': return 'Högsta befattningshavares funktion';
      case 'turnover_interval': return 'Omsättningsintervall';
      case 'nbr_employees_interval': return 'Anställda';
      case 'created_at': return 'Skapad';
      case 'updated_at': return 'Uppdaterad';
    }
  },
  label: (columnName: string): [string, string] => {
    return [columnName, csBasic.columnName(columnName)];
  },
};

export const csAccounts = {
  columnName: (columnName: string): string => {
    switch (columnName) {
      default: return columnName;
      case 'date_from': return 'Datum från';
      case 'date_to': return 'Datum till';
      case 'year': return 'År';
      case 'year_from': return 'År från';
      case 'year_to': return 'År till';
      case 'kr_return_on_capital_percent': return 'Avkastning på eget kapital';
      case 'kr_return_on_cap_emp_percent': return 'Avkastning på totalt kapital';
      case 'kr_avg_debt_eq_ratio_percent': return 'Skuldränta';
      case 'kr_risk_buffer': return 'Riskbuffert totalt kapital';
      case 'kr_ebitda': return 'EBITDA';
      case 'kr_ebitida_margin_percent': return 'EBITDA-marginal';
      case 'kr_interest_coverage_ebitda': return 'Räntetäckningsgrad EBITDA';
      case 'kr_return_on_total_capital_ebitda_percent': return 'Avkastning på totalt kapital EBITDA';
      case 'kr_return_on_working_capital_percent': return 'Avkastning på sysselsatt kapital';
      case 'kr_degree_of_debt_interest_bearing': return 'Skuldsättningsgrad Räntebärande';
      case 'kr_operating_margin_percent': return 'Rörelsemarginal';
      case 'kr_profit_margin_percent': return 'Vinstmarginal';
      case 'kr_gross_profit_margin_percent': return 'Bruttovinstmarginal';
      case 'kr_turnover_per_employee': return 'Omsättning per anställd';
      case 'kr_solidity_percent': return 'Soliditet';
      case 'kr_capital_turnover_times': return 'Kapitalets omsättningshastighet';
      case 'kr_working_capital': return 'Rörelsekapital';
      case 'kr_opp_profit_total_net_opp_income_percent': return 'Rörelsekapital/omsättning';
      case 'kr_quick_ratio_percent': return 'Kassalikviditet';
      case 'kr_change_in_turnover_percent': return 'Omsättningstillväxt';
      case 'kr_opp_result_number_of_employees': return 'Rörelseresultat per anställd';
      case 'kr_cost_per_employee': return 'Personalkostnader per anställd';
      case 'kr_change_in_number_of_employees': return 'Förändring av antal anställda';
      case 'kr_inventories_turnover_percent': return 'Lager mm/omsättning';
      case 'kr_accounts_receivable_turnover_percent': return 'Kundfordringar/omsättning';
      case 'kr_liquid_assets_turnover_percent': return 'Likvida medel/omsättning';
      case 'kr_current_liabilites_turnover_percent': return 'Kortfristiga skulder/omsättning';
      case 'kr_degree_of_debt': return 'Skuldsättningsgrad';
      case 'kr_rate_of_return_times': return 'Räntetäckningsgrad';
      case 'kr_return_on_op_capital': return 'Avkastning operativt kapital';
      case 'kr_risk_buffer_on_operation_capital_percent': return 'Riskbuffert sysselsatt kapital';
      case 'kr_inventory_turnover_times': return 'Varulagrets omsättningshastighet';
      case 'kr_du_pont_model_percent': return 'Du Pont-modellen';
      case 'pl_net_operating_income': return 'Omsättning';
      case 'pl_net_sales': return 'Nettoomsättning';
      case 'pl_cost_of_sold_goods': return 'Kostnad sålda varor';
      case 'pl_gross_profit_loss': return 'Bruttoresultat';
      case 'pl_selling_expenses': return 'Försäljningskostnader';
      case 'pl_admin_costs': return 'Administrationskostnader';
      case 'pl_research_and_dev_costs': return 'FoU-kostnader';
      case 'pl_change_invent_work_prog': return 'Förändring av lager mm';
      case 'pl_work_perf_own_use_capital': return 'Aktiverat arbete egen räkning';
      case 'pl_raw_mat_and_cons': return 'Råvaror & förnödenheter';
      case 'pl_goods_for_resale': return 'Handelsvaror';
      case 'pl_personal_costs': return 'Personalkostnader';
      case 'pl_depr_and_write_downs': return 'Avskrivningar';
      case 'pl_item_affect_compare': return 'Jämförelsestörande poster';
      case 'pl_other_opp_income': return 'Övriga rörelseintäkter';
      case 'pl_other_opp_expenses': return 'Övriga rörelsekostnader';
      case 'pl_other_ext_costs': return 'Övriga externa kostnader';
      case 'pl_operating_result': return 'Rörelseresultat (EBIT)';
      case 'pl_result_participation_group': return 'Resultat från andelar i koncern- och intresseföretag';
      case 'pl_fin_int_group_inc': return 'Ränteintäkter koncern';
      case 'pl_ext_interest_inc': return 'Ränteintäkter externa';
      case 'pl_interest_exp_int': return 'Räntekostnader koncern';
      case 'pl_interest_exp_ext': return 'Räntekostnader externa';
      case 'pl_other_fin_inc': return 'Övriga finansiella intäkter';
      case 'pl_other_fin_costs': return 'Övriga finansiella kostnader';
      case 'pl_prof_loss_after_fin_items': return 'Resultat efter finansnetto';
      case 'pl_extraordinary_inc': return 'Extraordinära intäkter';
      case 'pl_extraordinary_exp': return 'Extraordinära kostnader';
      case 'pl_group_contrib': return 'Koncernbidrag';
      case 'pl_shareholder_contrib': return 'Aktieägartillskott';
      case 'pl_other_appropriations': return 'Bokslutsdispositioner';
      case 'pl_tax': return 'Skatt';
      case 'pl_minority_intrest_and_prof': return 'Minoritetsintressen';
      case 'pl_net_profit_loss': return 'Årets resultat';
      case 'bs_subscribed_capital_unpaid': return 'Tecknat ej inbetalt kapital';
      case 'bs_capt_exp_for_res_and_dev': return 'Balanserade utgifter FoU';
      case 'bs_patents': return 'Patent, licenser mm';
      case 'bs_goodwill': return 'Goodwill';
      case 'bs_other_intangible_ass': return 'Övriga immateriella anläggningstillg.';
      case 'bs_total_intangible_assets': return 'Summa immateriella anläggningstillgångar';
      case 'bs_land_build': return 'Byggnader och mark';
      case 'bs_plant_machine': return 'Maskiner';
      case 'bs_equip_tools_fix_and_fit': return 'Inventarier';
      case 'bs_machines_inventory': return 'Maskiner och inventarier';
      case 'bs_other_tang_ass_non_dep': return 'Övriga materiella anläggnings tillgångar, ej avskrivningsbara';
      case 'bs_other_mat_depreciation': return 'Övriga materiella anläggningstillg., avskr.';
      case 'bs_total_tangible_assets': return 'Summa materiella anläggningstillgångar';
      case 'bs_group_share': return 'Andelar i koncern- och intresseföretag';
      case 'bs_acc_recieve_corp_group': return 'Fordr. på koncern- och intresseföretag';
      case 'bs_loan_co_owners': return 'Lån till delägare och närstående';
      case 'bs_other_fin_assets': return 'Övriga finansiella anläggningstillgångar';
      case 'bs_total_financial_assets': return 'Summa finansiella anläggningstillgångar';
      case 'bs_tot_fix_assets': return 'Summa anläggningstillgångar';
      case 'bs_work_on_contract': return 'Pågående arbeten för annans räkning';
      case 'bs_inventories': return 'Övrigt varulager';
      case 'bs_total_inventories': return 'Summa varulager';
      case 'bs_total_inventories_prev': return 'Summa varulager (föregående år)';
      case 'bs_total_inventories_change': return 'Förändring av varulager';
      case 'bs_accounts_receivable_trade': return 'Kundfordringar';
      case 'bs_acc_recieve_group': return 'Fordr. hos koncern- och intresseföretag';
      case 'bs_other_acc_recieve': return 'Övriga kortfristiga fordringar';
      case 'bs_total_accounts_receivable': return 'Summa kortfristiga fordringar';
      case 'bs_short_investments': return 'Kortfristiga placeringar';
      case 'bs_cash_and_bank_balances': return 'Kassa och bank';
      case 'bs_other_cur_assets': return 'Övriga omsättningstillgångar';
      case 'bs_total_turnover_assets': return 'Summa omsättningstillgångar';
      case 'bs_tot_assets': return 'Totala tillgångar';
      case 'bs_issued_share_capital': return 'Aktiekapital';
      case 'bs_share_premium_reserve': return 'Överkursfond';
      case 'bs_revaluation_reserve': return 'Uppskrivningsfond';
      case 'bs_other_stockholder_equity': return 'Övrigt bundet eget kapital';
      case 'bs_profit_loss_brought_fwd': return 'Balanserat resultat';
      case 'bs_group_contribution': return 'Erhållet/lämnat koncernbidrag';
      case 'bs_shareholder_contribution': return 'Erhållet/lämnat aktieägartillskott';
      case 'bs_net_profit_loss': return 'Årets resultat';
      case 'bs_total_dividens_capacity': return 'Summa Utdelningsbart utrymme';
      case 'bs_tot_equity': return 'Summa eget kapital';
      case 'bs_untaxed_reserves': return 'Obeskattade reserver';
      case 'corporate_tax_rate': return 'Bolagsskattesats';
      case 'bs_minority_interests': return 'Minoritetsintressen samt vinst/förlust i dotterbolag';
      case 'bs_provisions': return 'Avsättningar';
      case 'bs_lt_bond_loan': return 'Obligationslån';
      case 'bs_lt_liab_to_credit_instit': return 'Skulder till kreditinstitut, långa';
      case 'bs_lt_liab_group_ass_comp': return 'Skulder till koncern- och intresseföretag, långa';
      case 'bs_other_long_term_liab': return 'Övriga långfristiga skulder';
      case 'bs_tot_long_term_debts': return 'Summa långfristiga skulder';
      case 'bs_liab_credit_inst': return 'Skulder till kreditinstitut, korta';
      case 'bs_acc_pay_trade': return 'Leverantörsskulder';
      case 'bs_st_liab_group_ass_comp': return 'Skulder till koncern- och intresseföretag, korta';
      case 'bs_other_short_term_liab': return 'Övriga kortfristiga skulder';
      case 'bs_tot_current_liabilities': return 'Summa kortfristiga skulder';
      case 'bs_tot_liabilities': return 'Summa skulder';
      case 'bs_tot_equity_and_liab': return 'Summa eget kapital och skulder';
      case 'n_months': return 'Månader';
      case 'n_no_employees': return 'Antal anställda';
      case 'n_salary_board': return 'Löner till styrelse & VD';
      case 'n_tantiem_board': return 'Varav tantiem till styrelse & VD';
      case 'n_employee_salary': return 'Löner till övriga anställda';
      case 'n_performance_bonus_to_other_employees': return 'Varav resultatlön till övriga anställda';
      case 'n_payroll_overhead': return 'Sociala kostnader';
      case 'n_floating_charge': return 'Företagsinteckningar';
      case 'n_real_estate_mortgage': return 'Fastighetsinteckningar';
      case 'n_other_collateral': return 'Övriga säkerheter';
      case 'n_tot_collateral': return 'Summa säkerheter';
      case 'n_conditional_equity': return 'Villkorat aktieägartillskott';
      case 'n_other_contingent_liab': return 'Övriga ansvarsförbindelser';
      case 'n_tot_contingent_liab': return 'Summa ansvarsförbindelser';
      case 'n_dividend': return 'Utdelning';
      case 'n_accepted_bank_overdraft': return 'Beviljad checkräkningskredit';
      case 'n_not_used_bank_overdraft': return 'Utnyttjad checkräkningskredit';
      case 'n_net_interest_finance': return 'Räntenetto finansbolag';
      case 'n_depr_goods': return 'Avskrivningar varor';
      case 'n_depr_sales': return 'Avskrivning försäljning';
      case 'n_depr_admin': return 'Avskrivning administrativ';
      case 'n_depr_rod': return 'Avskrivning för avkastning på skuld';
      case 'n_depr_other': return 'Annan avskrivning';
      case 'n_depr_unspec': return 'Ospecifierad avskrivning';


      // some derived stuff not part of the normal concrete table
      case 'bs_adjusted_equity': return 'Justerat eget kapital';
      case 'bs_adjusted_equity_change': return 'Förändring av justerat eget kapital';
      case 'kr_net_margin_percent': return 'Nettomarginal';
      case 'kr_personal_costs_per_net_sales': return 'Personalkostnader/nettoomsättning';
      case 'pl_operating_result_change': return 'Förändring av rörelseresultat';
      case 'pl_personal_costs_change': return 'Förändring av personalkostnader';
    }
  },
};
