import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { UseTableSelectRows } from 'client/hooks/useTableSelectRows';
import {Zap} from 'react-feather';
import { UseMutationResult } from '@tanstack/react-query';
import Pluralizer from 'client/components/Pluralizer';
import LoadingButton from 'client/buttons/LoadingButton';
import ErrorAlert from 'client/components/ErrorAlert';

interface TableBatchFormProps {
  className?: string;
  tableSelectRows: UseTableSelectRows;
  initialAction?: string;
  actions: {value: string, label: string}[];
  batchMutation: UseMutationResult<unknown, Error, string>;
}

export default React.memo(TableBatchForm) as React.FC<TableBatchFormProps>;

function TableBatchForm (props: TableBatchFormProps) {
  const { className, tableSelectRows, batchMutation, actions, initialAction = '' } = props;

  const [action, setAction] = useState<string>(initialAction);

  const onChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    setAction(ev.target.value);
  }, [setAction]);

  const onSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    batchMutation.mutateAsync(action);
  }, [batchMutation.mutateAsync]);

  const disabled = useMemo(() => {
    return batchMutation.isPending || !tableSelectRows.selectedRowCount || !action || !actions.some(a => a.value === action);
  }, [batchMutation.isPending, tableSelectRows.selectedRowCount, action, actions]);

  return (
    <form onSubmit={onSubmit} className={className}>
      <div>
      Med <Pluralizer
        count={tableSelectRows.selectedRowCount}
        zero="0 markerade rader"
        one="1 markerad rad"
        more={`${tableSelectRows.selectedRowCount} markerade rader`}
      />:
      </div>
      <Form.Group>
        <Form.Select
          name="action"
          value={action}
          onChange={onChange}
          size="sm"
          required
          isInvalid={!action}
        >
          <option value="">Välj åtgärd</option>
          {actions.map(action => (
            <option key={action.value} value={action.value}>{action.label}</option>
          ))}
        </Form.Select>
      </Form.Group>
      <LoadingButton
        type="submit"
        size="sm"
        variant="outline-primary"
        disabled={disabled}
        isLoading={batchMutation.isPending}
      >
        <Zap size={14} />{' '}Utför
      </LoadingButton>
      <ErrorAlert className="m-0 p-2 w-100" error={batchMutation.error} />
    </form>
  );
}
