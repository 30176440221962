import React, { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { CompanyMonitoredUpdateWithRelationsRow } from 'client/companyMonitoredUpdate/types';
import { TableSpinningOverlay, TableCardFooterWithPagination } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import { TableProvider } from 'client/contexts/TableContext';

export interface CompanyMonitoredUpdateTableFilterParams {
  org_number?: string;
  change_code?: string;
  change_date_from?: string;
  change_date_to?: string;
}

interface CompanyMonitoredUpdateWithId extends CompanyMonitoredUpdateWithRelationsRow {
  id: string;
}

interface ListData {
  rows: CompanyMonitoredUpdateWithId[];
  total_rows: number;
}

const defaultFilterParams = {};

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CompanyMonitoredUpdateTablePage () {
  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const tableState = useTableState({
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible: filterDefinitions.map(f => f.id),
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/company_monitored_update/list', params],
    placeholderData: keepPreviousData,
  });

  const rows: CompanyMonitoredUpdateWithId[] = useMemo(() => {
    if (!Array.isArray(query.data?.rows)) return [];
    return (query.data?.rows || []).map(row => ({
      ...row,
      id: [row.org_number, row.change_code, row.change_date].join('-'),
    }));
  }, [query.data]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const allIds = useMemo(() => rows.map(item => item.id), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Bevakningsuppdateringar</title>
        </Helmet>
        <PageHeader className="mt-4 mb-2">Bevakningsuppdateringar</PageHeader>
        <p className="mb-4">
          Denna tabell visar bevakningsuppdateringar från Creditsafes tjänst för bolagsbevakningar.
          Varje rad representerar en händelse i en bevakningsportfölj hos Creditsafe.
        </p>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={query.isLoading || query.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={query.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={tablePagination?.totalCountOfRows || rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultColumnsVisible = [
  'select',
  'org_number',
  'change_date',
  'change_code',
  'change_text',
  'created_at',
  'updated_at',
  'actions',
];

const columnSortable = [
  'org_number',
  'change_date',
  'change_code',
  'change_text',
  'created_at',
  'updated_at',
];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.companySearch(['org_number', 'Företag']),
  filterDefs.input(['change_code', 'Kod'], {
    extraProps: {placeholder: 'Oavsett kod'},
  }),
  filterDefs.date(['change_date_from', 'Datum från'], {
    extraProps: {placeholder: 'Oavsett datum från'},
  }),
  filterDefs.date(['change_date_to', 'Datum till'], {
    extraProps: {placeholder: 'Oavsett datum till'},
  }),
];

const columnDefinitionFactory: ColumnDefinitionFactory<CompanyMonitoredUpdateWithId> = () => [
  columnDefs.select(),
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
  )),
  columnDefs.date(['change_date', 'Datum']),
  columnDefs.simple(['change_code', 'Kod']),
  columnDefs.simple(['change_text', 'Text']),

  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.date(['updated_at', 'Uppdaterad']),

  columnDefs.actions(),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
