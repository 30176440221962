import React, { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import CompanyTableBatchForm from 'client/company/CompanyTableBatchForm';
import ErrorAlert from 'client/components/ErrorAlert';
import PageHeader from 'client/components/PageHeader';
import RoleGuard from 'client/guards/RoleGuard';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import { Card } from 'react-bootstrap';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import { Helmet } from 'react-helmet';
import { ICsBasicExtended } from 'client/cs/types';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TableProvider } from 'client/contexts/TableContext';
import * as companyTableFilters from 'client/company/tableFilters';
import * as companyTableColumns from 'client/company/tableColumns';

export interface CompanyTableFilterParams {
  search_term?: string;
  nbr_employees_interval?: string;
  sni_code?: string;
  legalgroup_code?: string;
}

export interface CsBasicExtendedRow extends ICsBasicExtended {
  id: string;
}

interface ListData {
  rows: ICsBasicExtended[];
  total_rows: number;
}

const defaultState = {
  orderBy: 'org_number',
  orderDirection: OrderDirection.ASC,
  legalgroup_code: 'AB',
};

export default React.memo(function CompanyTablePage () {
  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const tableState = useTableState({
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible,
    rowsPerPageMax: 500,
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/company/list', params],
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    const count = listQuery.data?.total_rows ?? 0;
    tablePagination.onChangeTotalCountOfRows(count);
  }, [listQuery.data?.total_rows]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const rows = useMemo(() => {
    const rows = listQuery.data?.rows ?? [];
    return rows.map(obj => ({...obj, id: obj.org_number}));
  }, [listQuery.data?.rows]);

  const allIds = useMemo(() => rows.map(item => item.org_number), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Alla företag</title>
        </Helmet>
        <PageHeader>Alla företag</PageHeader>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              onReload={listQuery.refetch}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              {...tableState}
            />
          </Card.Header>
          <RoleGuard role={['admin', 'coordinator']}>
            <CompanyTableBatchForm
              tableSelectRows={tableSelectRows}
              columnsVisible={columnsVisible}
              columnDefinitions={columnDefinitions}
            />
          </RoleGuard>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
});

const defaultFiltersVisible = [
  'search_term',
  'branch_id',
  'legalgroup_code',
];

const defaultColumnsVisible = [
  'select',
  'org_number',
  'company_name',
  'registered_town',
  'sni_code',
  'legalgroup_text',
  'status_text_high',
  'registration_date',
  'nbr_employees_interval',
  'created_at',
  'actions',
];

const columnSortable = [
  'org_number',
  'company_name',
  'created_at',
];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  ...companyTableFilters.companyControllerList(),
];

const columnDefinitionFactory: ColumnDefinitionFactory<CsBasicExtendedRow> = () => [
  columnDefs.select(),
  ...companyTableColumns.companyControllerList(),
  columnDefs.actions(),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
