import React, { useMemo, useState } from 'react';


interface InputFormattedProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  Component: React.ComponentType<any>;
  value: '' | number;
  formatting?: Intl.NumberFormatOptions;
  scale?: number;
  [key: string]: React.ComponentProps<any>;
}

// NOTE
// experimental component
export default function InputFormatted (props: InputFormattedProps) {
  const {
    onFocus:onFocusOuter,
    onBlur:onBlurOuter,
    onChange:onChangeOuter,
    value:valueOuter,
    formatting = {},
    // scale = 1,
    Component,
    ...restOfProps
  } = props;

  const fmt = useMemo(() => new Intl.NumberFormat('sv-SE', formatting), [formatting]);

  const [focused, setFocused] = useState(false);

  const onFocus: React.FocusEventHandler<HTMLInputElement> = ev => {
    setFocused(true);
    onFocusOuter?.(ev);
  };

  const onBlur: React.FocusEventHandler<HTMLInputElement> = ev => {
    setFocused(false);
    const valueAsNumber = isNaN(ev.target.valueAsNumber) ? '' : ev.target.valueAsNumber;
    ev.target.value = String(valueAsNumber);
    onBlurOuter?.(ev);
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const value = isNaN(ev.target.valueAsNumber) ? '' : fmt.format(ev.target.valueAsNumber);
    onChangeOuter?.(ev, value);
  };

  const value = useMemo(() => {
    if (valueOuter === '') return '';
    if (focused) return valueOuter;
    return fmt.format(valueOuter);
  }, [focused, valueOuter, fmt]);

  return (
    <Component
      {...restOfProps}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      type={focused ? 'number' : 'text'}
      inputMode="numeric"
      value={value}
    />
  );
}



