import React from 'react';
import { Table, TableProps } from 'react-bootstrap';
import {CustomerSurveyWithRelationsRow} from 'client/customerSurvey/types';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import DateFormat from 'client/components/DateFormat';
import classNames from 'classnames';

interface CustomerSurveyPropertiesTableProps extends TableProps {
  customerSurvey: CustomerSurveyWithRelationsRow;
}

export default function CustomerSurveyPropertiesTable (props: CustomerSurveyPropertiesTableProps) {
  const { customerSurvey, className, ...restOfProps } = props;
  return (
    <Table {...restOfProps} className={classNames(className, 'no-border-bottom')}>
      <tbody>
        <tr>
          <th>Statusdatum</th>
          <td><DateFormat value={customerSurvey.status_updated_at} /></td>
        </tr>
        <tr>
          <th>Kund</th>
          <td>
            <CustomerFormatters.CustomerAnchor value={customerSurvey.customer} />
          </td>
        </tr>
        <tr>
          <th>Öppnad</th>
          <td><DateFormat value={customerSurvey.opened_at} /></td>
        </tr>
        <tr>
          <th>Besvarad</th>
          <td><DateFormat value={customerSurvey.answered_at} /></td>
        </tr>
        <tr>
          <th>Betyg</th>
          <td>
            {customerSurvey.rating !== null ? (
              <>
                {customerSurvey.rating} av 5
              </>
            ) : <>-</>}
          </td>
        </tr>
        <tr>
          <th>Kommentar</th>
          <td>
            {customerSurvey.comment !== null ? (
              <>
                {customerSurvey.comment}
              </>
            ) : <>-</>}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
