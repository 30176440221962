import { CustomerRow, CustomerRowWithReportCounts } from 'client/customer/types';
import StatusBadge from 'client/components/StatusBadge';
import { OverlayTrigger, Tooltip, BadgeProps } from 'react-bootstrap';
import AnchorWithFlag from 'client/anchors/AnchorWithFlag';

interface CustomerAnchorProps {
  value?: null | string | Partial<CustomerRow>;
  inactivated?: boolean;
}

export function CustomerAnchor (props: CustomerAnchorProps) {
  const { value, inactivated } = props;
  if (value === null || typeof value === 'undefined') return null;
  if (typeof value === 'string') {
    return (
      <AnchorWithFlag to={`/customer/${value}/form`} inactivated={inactivated}>
        {value}
      </AnchorWithFlag>
    );
  }

  const { id, name, inactivated_at } = value as Partial<CustomerRow>;
  return (
    <AnchorWithFlag to={`/customer/${id}/form`} inactivated={Boolean(inactivated_at)}>
      {name ?? id}
    </AnchorWithFlag>
  );
}

interface CustomerStringProps {
  value: Partial<CustomerRow>;
}
export function CustomerString (props: CustomerStringProps) {
  const { value: { id, name } } = props;
  if (!id) return null;
  if (!name) return <>{id}</>;
  return <>{id} - {name}</>;
}

// FIXME find new icons?

interface CustomerReportCountsProps {
  value: CustomerRowWithReportCounts;
  hideZero?: boolean;
}
export function CustomerReportCounts (props: CustomerReportCountsProps) {
  const { value:customer, hideZero = false } = props;
  const { valuation_reports_count:valuation = 0, industry_reports_count:industry = 0, report_queues_count:report = 0 } = customer;
  return (
    <div className="d-flex align-items-center gap-1">
      {!(hideZero && valuation === 0) && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip style={{position: 'fixed'}}>Företagsvärderingar</Tooltip>}
        >
          <span className="badge border border-primary text-primary">
            V: {valuation}
          </span>
        </OverlayTrigger>
      )}
      {!(hideZero && industry === 0) && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip style={{position: 'fixed'}}>Branschrapporter</Tooltip>}
      >
        <span className="badge border border-warning text-warning">
          B: {industry}
        </span>
      </OverlayTrigger>
      )}
      {!(hideZero && report === 0) && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip style={{position: 'fixed'}}>Köade rapporter</Tooltip>}
      >
        <span className="badge border border-secondary text-secondary">
          R: {report}
        </span>
      </OverlayTrigger>
      )}
    </div>
  );
}

interface StatusLabelProps extends BadgeProps {
  value: CustomerRow['inactivated_at'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return value === null ? (
    <StatusBadge bg="success" {...restOfProps}>Aktiv</StatusBadge>
  ) : (
    <StatusBadge bg="warning" {...restOfProps}>Inaktiverad</StatusBadge>
  );
}

export function fieldName (fieldName: string): string {
  switch (fieldName) {
    default: return fieldName;
    case 'id': return 'ID';
    case 'name': return 'Namn';
    case 'email': return 'E-post';
    case 'email_verified': return 'E-post verifierad';
    case 'password': return 'Lösenord';
    case 'last_login_at': return 'Senaste inloggning';
    case 'remember_token': return 'Minnestoken';
    case 'inactivated_at': return 'Inaktiveringsdatum';
    case 'created_at': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'associated_portal_user_ids': return 'Associerade ID';
    case 'last_login_ip': return 'Senaste inloggnings-IP';
    case 'contact_person': return 'Kontaktperson';
    case 'created_by_user_id': return 'Skapare';
    case 'language': return 'Språk';
    case 'company_monitored_limit': return 'Max antal bevakade företag';
    case 'company_monitored_settings': return 'Bevakningsinställningar';
    case 'company_monitored_event_types': return 'Bevakade händelser';
  }
}
