import React, { useMemo, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { CsEventRowWithRelations } from 'client/cs/types';
import PageHeader from 'client/components/PageHeader';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import { TableProvider } from 'client/contexts/TableContext';
import * as CompanyFormatters from 'client/company/CompanyFormatters';

export interface CsEventTableFilterParams {
  search_term?: string;
}

interface ListData {
  rows: CsEventRowWithRelations[];
  total_rows: number;
}

export default function CsEventTablePage () {
  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const tableState = useTableState({
    defaultColumnsVisible,
    defaultFiltersVisible: filterDefinitions.map(f => f.id),
    defaultState: {
      orderBy: 'change_date',
      orderDirection: OrderDirection.DESC,
    },
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const listQuery = useQuery<ListData>({
    queryKey: ['/api/cs/event/list', params],
    placeholderData: keepPreviousData,
  });

  const rows = useMemo(() => {
    return (listQuery.data?.rows ?? []).map(row => ({
      id: [row.org_number, row.change_date, row.change_code].join('_'),
      ...row,
    }));
  }, [listQuery.data?.rows]);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(listQuery.data?.total_rows ?? 0);
  }, [listQuery.data?.total_rows]);

  return (
    <PageContainer fluid>
      <TableProvider tableSort={tableSort}>
        <Helmet>
          <title>Creditsafe-händelser</title>
        </Helmet>
        <PageHeader className="mt-4 mb-2">Creditsafe-händelser</PageHeader>
        <p className="mb-4">
          Denna tabell visar händelser från tabellen <code>cs_events</code> som importeras
          som en del av bulkdatan från Creditsafe.
        </p>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={listQuery.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultColumnsVisible = [
  'org_number',
  'change_date',
  'change_code',
  'change_text_swedish',
  'actions',
];

const columnSortable = [
  'org_number',
  'change_date',
];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.companySearch(['org_number', 'Företag']),
  filterDefs.input(['change_code', 'Kod'], {
    extraProps: {placeholder: 'Oavsett kod'},
  }),
  filterDefs.date(['change_date_from', 'Datum från'], {
    extraProps: {placeholder: 'Oavsett datum från'},
  }),
  filterDefs.date(['change_date_to', 'Datum till'], {
    extraProps: {placeholder: 'Oavsett datum till'},
  }),
];

const columnDefinitionFactory: ColumnDefinitionFactory<any> = () => [
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
  )),
  columnDefs.date(['change_date', 'Datum']),
  columnDefs.simple(['change_code', 'Kod']),
  columnDefs.simple(['change_text_swedish', 'Text']),
  columnDefs.simple(['change_text_swedish_detailed', 'Text (detaljerad)']),
  columnDefs.simple(['complete_ef_pnr', 'Fullständigt EF personnummer']),
  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.date(['updated_at', 'Uppdaterad']),
  columnDefs.actions(),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
