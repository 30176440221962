import React from 'react';
import classNames from 'classnames';
import { Trash2, Triangle } from 'react-feather';
import { UseTableSorting } from 'client/hooks/useTableSorting';
import { UseTablePagination } from 'client/hooks/useTablePagination';
import { Button, Card, Form, FormGroupProps, InputGroup, InputGroupProps } from 'react-bootstrap';
import Pagination from 'client/table/TablePagination';
import NumberFormat from 'client/components/NumberFormat';
import OverlaySpinner from 'client/spinners/OverlaySpinner';
import Pluralizer from 'client/components/Pluralizer';
import {ColumnDefinition, FilterDefinition, GenericRow} from 'client/table/types';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import moment from 'moment';

export interface TableOrderByProps extends UseTableSorting {
  title: React.ReactNode;
  column: string;
}

export function TableOrderBy (props: TableOrderByProps) {
  const {
    column,
    title,
    isOrderedBy,
    isAsc,
    isDesc,
    toggleDirection,
    onSetOrder,
  } = props;
  const isActive = isOrderedBy(column);

  const onClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (isOrderedBy(column)) {
      toggleDirection();
    } else {
      onSetOrder(column);
    }
  };

  return (
    <button
      type="button"
      className="d-flex align-items-center gap-1 btn-reset"
      onClick={onClick}
      style={{fontWeight: 'bold'}}
    >
      <p className="m-0">{title}</p>
      <div>
        <Triangle
          size={10}
          className={classNames('d-block', { 'fill-base': isActive && isAsc })}
          role="button"
        />
        <Triangle
          size={10}
          className={classNames('d-block vertical-flip', { 'fill-base': isActive && isDesc })}
          role="button"
        />
      </div>
    </button>
  );
}

interface TableSpinningOverlayProps extends React.PropsWithChildren {
  isLoading: boolean;
}
export function TableSpinningOverlay (props: TableSpinningOverlayProps) {
  const { isLoading, children } = props;
  return (
    <div className="position-relative">
      {isLoading && (
        <OverlaySpinner isLoading={isLoading} />
      )}
      {children}
    </div>
  );
}

interface TableCardFooterProps extends React.PropsWithChildren {

}

export function TableCardFooter (props: TableCardFooterProps) {
  const { children } = props;
  return (
    <Card.Footer className="border-top-0 p-3 d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap">
      {children}
    </Card.Footer>
  );
}

interface TableCardFooterWithPaginationProps {
  tablePagination?: UseTablePagination;
  rowLength: number;
}

export function TableCardFooterWithPagination (props: TableCardFooterWithPaginationProps) {
  const { tablePagination, rowLength } = props;
  return (
    <TableCardFooter>
      <p className="mb-sm-0">
        Totalt <NumberFormat value={tablePagination?.totalCountOfRows || rowLength} />{' '}
        <Pluralizer
          count={tablePagination?.totalCountOfRows || rowLength}
          zero="rader"
          one="rad"
          more="rader"
        /> i denna tabell
      </p>
      {tablePagination && (
        <Pagination withRowsPerPage {...tablePagination} />
      )}
    </TableCardFooter>
  );
}

interface TableFilterInputGroupProps extends FormGroupProps {
  name: string;
  label: React.ReactNode;
  description?: string;
  size?: InputGroupProps['size'];
  onReset?: () => void;
  resetDisabled?: boolean;
}

export function TableFilterInputGroup (props: TableFilterInputGroupProps) {
  const { label, children, size, resetDisabled = true, onReset, description, ...restOfProps } = props;
  return (
    <Form.Group {...restOfProps}>
      <InputGroup size={size}>
        <InputGroup.Text title={description}>{label}</InputGroup.Text>
        {children}
        {onReset && (
          <Button
            variant="outline-secondary"
            disabled={resetDisabled}
            onClick={onReset}
            title="Nollställ filter"
            className="d-flex align-items-center px-2"
          >
            <Trash2 size={18} />
          </Button>
        )}
      </InputGroup>
    </Form.Group>
  );
}

interface DownloadTableAsCSVParams {
  columnsVisible: string[];
  columnDefinitions: ColumnDefinition<any>[];
}

export function downloadTableAsCSV (rows: GenericRow[], params: DownloadTableAsCSVParams) {
  const filterColumnById = (columnId: string) => !['select', 'actions'].includes(columnId);
  const { columnsVisible, columnDefinitions } = params;
  const columns = columnsVisible.filter(filterColumnById);
  const div = document.createElement('div');
  const root = createRoot(div);
  const columnSeparator = '\x1F';
  const rowSeparator = '\x1E';

  flushSync(() => {
    root.render(
      <BrowserRouter>
        {rows.map((row, rowIndex) => {
          return (
            <div key={row.org_number}>
              {columns.map((columnId, columnIndex) => {
                const column = columnDefinitions.find(column => column.id === columnId);
                if (!column) {
                  throw new Error(`Kan ej hitta kolumnen med ID "${columnId}"`);
                }

                return (
                  <>
                    <column.Body row={row} key={columnId} />
                    {columnIndex < columns.length - 1 && columnSeparator}
                  </>
                );
              })}
              {rowIndex < rows.length - 1 && rowSeparator}
            </div>
          );
        })}
      </BrowserRouter>,
    );
  });

  const delimiter = ',';
  const escapeValue = (value: string) => value.includes(delimiter) ? `"${value}"` : value;
  const firstRow = columnsVisible
    .filter(filterColumnById)
    .map(columnId => columnDefinitions.find(column => column.id === columnId)?.label as string)
    .map(escapeValue)
    .join(delimiter);

  const formattedRows = div.innerText
    .split(rowSeparator)
    .map(row => row.split(columnSeparator)
      .map(escapeValue)
      .join(delimiter),
    );

  if (formattedRows.length !== rows.length) {
    throw new Error('Det går inte att exportera de valda raderna');
  }

  const formattedAsCSV = [firstRow, ...formattedRows].join('\n');
  const blob = new Blob([formattedAsCSV], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `export_${moment().format('YYYY-MM-DD_HH:mm:ss')}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

interface TableFilterRenderProps {
  value?: any;
  filterDefinition: FilterDefinition<any>;
  onChange: (id: string, value: any) => void;
  size?: 'sm';
}

export function TableFilterRenderDefinition (props: TableFilterRenderProps) {
  const { value, filterDefinition, size, onChange } = props;
  const { id, label, description, validate, Render, extraProps = {} } = filterDefinition;
  return (
    <Render
      extraProps={extraProps}
      value={value}
      description={description}
      id={id}
      label={label ?? id}
      size={size}
      onChange={onChange}
      validate={validate}
    />
  );
}
