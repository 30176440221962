import React, {useMemo} from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import axios from 'client/axios';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import DateFormat from 'client/components/DateFormat';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { IEmail } from 'client/email/types';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import EmailRenderModal from 'client/email/EmailRenderModal';
import SendCustomerEmailButton from 'client/email/SendCustomerEmailButton';
import {ColumnDefinition} from 'client/table/types';
import TableVirtual from 'client/table/TableVirtual';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as EmailFormatters from 'client/email/EmailFormatters';

interface CustomerEmailsTableProps {
  customerId: string;
  className?: string;
}

const CustomerEmailsTable: React.FC<CustomerEmailsTableProps> = React.memo(function CustomerEmailsTable (props: CustomerEmailsTableProps) {
  const { className, customerId } = props;

  const query = useQuery<IEmail[], Error>({
    queryKey: ['CustomerEmailsTable', customerId],
    queryFn: () => axios.get(`/api/emails/customer/${customerId}`).then(r => r.data),
  });

  const onSuccessSendEmail = () => {
    query.refetch();
  };

  const rows = useMemo(() => {
    return (query.data ?? []).map(row => ({...row, id: String(row.id)}));
  }, [query.data]);

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Kundens e-postmeddelanden
          <div>
            <RefreshButton
              onClick={query.refetch}
              disabled={query.isLoading || query.isRefetching}
              className="px-2"
            />
          </div>
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      <TableSpinningOverlay isLoading={query.isRefetching}>
        <div className="table-responsive border-start border-end">
          <TableVirtual
            className="mb-0 align-middle"
            rows={rows}
            columns={columns}
            isInitialLoading={query.isLoading}
          />
        </div>
      </TableSpinningOverlay>
      <Card.Footer className="border border-top-0 d-flex justify-content-end gap-2 p-2 flex-wrap align-items-center">
        <strong>Skicka e-post: </strong>
        <SendCustomerEmailButton
          size="sm"
          customerId={customerId}
          emailTemplateIdOrType={9}
          onSuccess={onSuccessSendEmail}
          variant="outline-primary"
        >
          Välkommen till Calculate
        </SendCustomerEmailButton>
        <SendCustomerEmailButton
          size="sm"
          customerId={customerId}
          emailTemplateIdOrType={10}
          onSuccess={onSuccessSendEmail}
          variant="outline-primary"
        >
          Välkommen till bevakningsfunktionen
        </SendCustomerEmailButton>
        <SendCustomerEmailButton
          size="sm"
          customerId={customerId}
          emailTemplateIdOrType="notifyNewReport"
          onSuccess={onSuccessSendEmail}
          variant="outline-primary"
        >
          Ny rapport
        </SendCustomerEmailButton>
        <SendCustomerEmailButton
          size="sm"
          customerId={customerId}
          emailTemplateIdOrType="resetPassword"
          onSuccess={onSuccessSendEmail}
          variant="outline-primary"
        >
          Lösenordsåterställning
        </SendCustomerEmailButton>
      </Card.Footer>
    </Card>
  );
});
export default CustomerEmailsTable;

const columns: ColumnDefinition[] = [
  columnDefs.simple(['subject', 'Ämne']),
  columnDefs.cell(['to', 'Mottagare'], props => <EmailFormatters.EmailRecipient value={props.row} />),
  columnDefs.cell(['created_at', 'Skickat'], props => (
    <DateFormat value={props.row.created_at} format="YYYY-MM-DD HH:mm" />
  )),
  columnDefs.actions(props => (
    <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
      <ModalOpeningButton
        variant="outline-primary"
        Modal={EmailRenderModal as React.FC}
        modalProps={{emailId: props.row.id}}
        className="py-0"
        size="sm"
      >
        Visa
      </ModalOpeningButton>
    </div>
  )),
];
