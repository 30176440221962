import React, { useMemo, useState } from 'react';
import {TableFilterSimpleModalBodyProps} from 'client/table/TableFilterSimpleModal';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import {BranchRow} from 'client/branches/types';
import BlockSpinner from 'client/spinners/BlockSpinner';
import IdProvider from 'client/components/IdProvider';
import ErrorAlert from 'client/components/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import * as BranchFormatters from 'client/branches/BranchFormatters';

export default function BranchSearchModalBody (props: TableFilterSimpleModalBodyProps) {
  const { value, setValue } = props;

  const selectedBranchIds = (value || '').split(',').filter(v => v);

  const onChangeSelectedBranch: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const branchId = ev.target.value;
    if (ev.target.checked) {
      if (!selectedBranchIds.includes(branchId)) {
        setValue([...selectedBranchIds, branchId].join(','));
      }
    } else {
      if (selectedBranchIds.includes(branchId)) {
        setValue(selectedBranchIds.filter(id => branchId !== id).join(','));
      }
    }
  };

  const [searchTerm, setSearchTerm] = useState<string>('');

  const onChangeSearchTerm: React.ChangeEventHandler<HTMLInputElement> = ev => {
    setSearchTerm(ev.target.value);
  };

  const onClickResetSearchTerm = () => {
    setSearchTerm('');
  };

  const searchTermRegExp = useMemo(() => {
    return new RegExp(searchTerm, 'i');
  }, [searchTerm]);

  const query = useQuery<BranchRow[]>({
    queryKey: ['/api/branch'],
  });

  const branches = query.data ?? [];

  // we map selectedBranchIds this way because we want the selected branches to appear in the order they were selected
  // i.e. most recently selected branch last
  const selectedBranches = selectedBranchIds.map(branchId => branches.find(branch => branch.id === branchId)).filter(v => v) as BranchRow[];

  const unselectedBranches = branches.filter(branch => {
    const { id, branch_name } = branch;
    if (selectedBranchIds.includes(branch.id)) return false;
    if (!searchTerm) return true;
    return searchTermRegExp.test(id) || searchTermRegExp.test(branch_name);
  });

  return (
    <Modal.Body style={{overflowY: 'hidden'}} className="p-0">
      <ErrorAlert error={query.error} />
      <BlockSpinner isLoading={query.isLoading} />
      <Form>
        <div className="border-bottom p-3">
          {selectedBranches.length > 0 ? (
            <>
              {selectedBranches.map(branch => (
                <IdProvider key={branch.id}>
                  {id => (
                    <Form.Check
                      inline
                      id={id}
                      label={<BranchFormatters.BranchLabel branch={branch} />}
                      onChange={onChangeSelectedBranch}
                      checked
                      value={branch.id}
                    />
                  )}
                </IdProvider>
              ))}
            </>
          ) : (
            <span className="text-muted ">Markerade branscher visas här.</span>
          )}
        </div>
        <Form.Group className="border-bottom p-3 m-0">
          <InputGroup>

          <Form.Control
            placeholder="Ange en sökterm"
            value={searchTerm}
            onChange={onChangeSearchTerm}
          />
            <Button
              variant="outline-secondary"
              onClick={onClickResetSearchTerm}
              disabled={!searchTerm}
            >
              Töm
            </Button>
          </InputGroup>
        </Form.Group>
        <div className="p-3" style={{overflowY: 'auto', height: '300px'}}>
          {unselectedBranches.length > 0 ? (
            <>
              {unselectedBranches.map(branch => (
                <IdProvider key={branch.id}>
                  {id => (
                    <Form.Check
                      id={id}
                      label={<BranchFormatters.BranchLabel branch={branch} />}
                      onChange={onChangeSelectedBranch}
                      value={branch.id}
                    />
                  )}
                </IdProvider>
              ))}
            </>
          ) : (
            <span className="text-muted">Det finns inga branscher här.</span>
          )}
        </div>
      </Form>
    </Modal.Body>
  );
}
