import React, { createContext, useContext, PropsWithChildren, FC } from 'react';
import { UseTableSorting } from 'client/hooks/useTableSorting';
import { UseTableSelectRows } from 'client/hooks/useTableSelectRows';

export type TableContextValue = {
  tableSort?: UseTableSorting;
  tableSelectRows?: UseTableSelectRows;
  [key: string]: any;
}

type TableProviderProps = PropsWithChildren<TableContextValue>;

const TableContext = createContext<TableContextValue>({});

const TableProvider: FC<TableProviderProps> = React.memo(function TableProvider (props) {
  const { children, ...rest } = props;
  return (
    <TableContext.Provider value={rest}>
      {children}
    </TableContext.Provider>
  );
});

function useTableContext (): TableContextValue {
  const context = useContext(TableContext) as TableContextValue;
  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider');
  }
  return context;
}

function useMaybeTableContext (): TableContextValue {
  return useContext(TableContext);
}

export { TableContext, TableProvider, useTableContext, useMaybeTableContext };
