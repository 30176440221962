import React from 'react';
import SendEmailButton, { SendEmailButtonProps } from 'client/email/SendEmailButton';

interface SendCompanyEventBatchEmailButtonProps extends Omit<SendEmailButtonProps, 'objectType' | 'objectId' | 'emailTemplateIdOrType'> {
  companyEventBatchId: string;
  objectType: 'companyEventUserBatch' | 'companyEventCustomerBatch';
  onSuccess?: () => void;
}

const SendCompanyEventBatchEmailButtonProps: React.FC<SendCompanyEventBatchEmailButtonProps> = React.memo(function SendCompanyEventBatchEmailButton (props: SendCompanyEventBatchEmailButtonProps) {
  const { companyEventBatchId, objectType, ...restOfProps } = props;


  return (
    <SendEmailButton
      objectType={objectType}
      objectId={companyEventBatchId}
      {...restOfProps}
      emailTemplateIdOrType="companyEventBatch"
    />
  );
});
export default SendCompanyEventBatchEmailButtonProps;

