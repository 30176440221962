import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import LoadingButton from 'client/buttons/LoadingButton';
import { UserCompanyMonitoredRow } from 'client/userCompanyMonitored/types';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import axios from 'client/axios';
import {Eye, EyeOff} from 'react-feather';

interface UserCompanyMonitoredQuickButtonProps extends ButtonProps {
  orgNumber: string;
  userId?: string;
}

export default function UserCompanyMonitoredQuickButton (props: UserCompanyMonitoredQuickButtonProps) {
  const { orgNumber, userId, variant:outerVariant, ...restOfProps } = props;

  const queryClient = useQueryClient();

  const url = `/api/user_company_monitored/me/company/${orgNumber}`;

  const query = useQuery<UserCompanyMonitoredRow | null, Error>({
    queryKey: [url],
  });

  const mutation = useMutation<any, Error>({
    mutationFn: async () => {
      if (isMonitored) {
        await axios.delete(url);
        queryClient.setQueryData([url], () => null);
        requestCallbacks.onSuccess('Bevakningen har tagits bort');
      } else {
        const result = await axios.put(url, {});
        queryClient.setQueryData([url], () => result.data);
        requestCallbacks.onSuccess('Bevakningen har lagts till');
      }
    },
    onError: err => {
      requestCallbacks.onError(err);
    },
  });

  const onClick: React.MouseEventHandler = () => {
    mutation.mutateAsync();
  };

  const isMonitored: boolean | null = query.isSuccess ? Boolean(query.data) : null;
  if (isMonitored === null) return null;

  const variant = isMonitored ? outerVariant : `outline-${outerVariant}`;

  return (
    <LoadingButton
      {...restOfProps}
      variant={variant}
      onClick={onClick}
      isLoading={mutation.isPending || query.isFetching}
      disabled={mutation.isPending || query.isFetching}
    >
      {isMonitored ? (
        <><EyeOff size={18} /> Ta bort bevakning</>
      ) : (
        <><Eye size={18} /> Bevaka företaget</>
      )}
    </LoadingButton>
  );
}
